import ApplicationController from './application_controller';

export default class extends ApplicationController {
  static targets = [
    'fixedHeader',
    'features',
    'scrollObserver',
    'syncScroll'
  ];

  static values = {
    cohort: Boolean,
  }

  toggleFeaturesList() {
    this.init();
  }

  init() {
    this.clonedHeader = this.fixedHeaderTarget.cloneNode(true);
    if (!this.cohortValue) this.clonedHeader.style.padding = 0;
    this.clonedHeader.style.margin = 0;
    this.clonedHeader.style.paddingTop = '18px';
    const fixedHidden = this.clonedHeader.querySelectorAll('.fixed-hidden');
    if (fixedHidden.length) {
      fixedHidden.forEach((el) => {
        el.remove();
      });
    };
    this.fixedHeaderContainer = this.element.cloneNode();
    this.fixedHeaderContainer.removeAttribute('data-controller');
    this.fixedHeaderContainer.appendChild(this.clonedHeader);
    this.createIntersectionObserver();
    this.syncScroll();
  }

  syncScroll() {
    this.syncScrollTargets.forEach(target => target.addEventListener('scroll', () => {
      this.syncScrollTargets.forEach(t => {
        t.scrollLeft = target.scrollLeft;
      });
    }));
  }

  createIntersectionObserver() {
    if (!this.hasFixedHeaderTarget || !this.hasScrollObserverTarget) return;
    const observer = new IntersectionObserver(
      entries => this.handleIntersect(entries), {
        rootMargin: '100px'
      }
    );
    observer.observe(this.scrollObserverTarget);
  }

  handleIntersect(entries) {
    this.fixedHeaderContainer.style.position = 'fixed';
    this.fixedHeaderContainer.style.zIndex = 100000;
    this.fixedHeaderContainer.style.top = 0;
    this.fixedHeaderContainer.style.left = 0;
    this.fixedHeaderContainer.style.right = 0;
    this.fixedHeaderContainer.style.paddingTop = 0;
    this.fixedHeaderContainer.style.paddingBottom = 0;
    entries.forEach(entry => {
      if (!entry.isIntersecting) {
        this.element.appendChild(this.fixedHeaderContainer);
      } else {
        if (document.contains(this.fixedHeaderContainer)) {
          this.element.removeChild(this.fixedHeaderContainer);
        }
      }
    })
  }
}