import ApplicationController from './application_controller';

export default class extends ApplicationController {
  popupHandler(e) {
    e.preventDefault();
    const popupSize = 500;
    const elem = (e ? e : window.event);
    const target = (elem.target ? elem.target : elem.srcElement);
    const px = Math.floor(((screen.availWidth || 1024) - popupSize) / 2),
          py = Math.floor(((screen.availHeight || 700) - popupSize) / 2);
    const popup = window.open(
          target.href, 
          'social',
          'width=' + popupSize + ',height=' + popupSize +
          ',left=' + px + ',top=' + py +
          ',location=0,menubar=0,toolbar=0,status=0,scrollbars=1,resizable=1');
    if(popup) {
      popup.focus();
      if (elem.preventDefault) elem.preventDefault();
      elem.returnValue = false;
    }
    return !!popup;
  }
}