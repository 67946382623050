import ApplicationController from "./application_controller";
import { postMessage } from "vendor/helpers";

export default class extends ApplicationController {
  static targets = [];

  static values = {
    accessibleChannels: Boolean
  };

  connect() {
    this.checkforAccessibleChannels();
  }

  turboLoad() {
    this.checkforAccessibleChannels();
  }

  checkforAccessibleChannels() {
    if (this.accessibleChannelsValue) {
      postMessage("hasAccessibleChannels");
    }
  }
}
