import ApplicationController from './application_controller';
import { UAParser } from 'ua-parser-js'; 

export default class extends ApplicationController {
  static targets = [
    'downloadButton',
    'downloadTitle',
    'downloadIntro',
    'downloadDescription'
  ]

  connect() {
    let parser = new UAParser();

    switch (parser.getOS().name) {
      case 'Mac OS':
        this.downloadTitleTarget.textContent = 'Mixlr for Mac';
        this.downloadButtonTarget.href = this.downloadButtonTarget.getAttribute('data-macos-download');
        this.downloadButtonTarget.classList.add('demo-download');
        break;
      case 'Windows':
        this.downloadTitleTarget.textContent = 'Mixlr for Windows';
        this.downloadButtonTarget.href = this.downloadButtonTarget.getAttribute('data-windows-download');
        this.downloadButtonTarget.classList.add('demo-download');
        break;
      case 'iOS':
        this.downloadTitleTarget.textContent = 'Mixlr for iOS';
        this.downloadButtonTarget.href = this.downloadButtonTarget.getAttribute('data-ios-download');
        this.downloadButtonTarget.textContent = 'Visit app store';
        break;
      case 'Android':
        this.downloadTitleTarget.textContent = 'Mixlr for Android';
        this.downloadButtonTarget.href = this.downloadButtonTarget.getAttribute('data-android-download');
        this.downloadButtonTarget.textContent = 'Visit play store';
        break;
      default:
        break;
    }
  }

  showInstructions(event) {
    if (event.target.classList.contains('demo-download')) {
      this.downloadIntroTarget.style.display = 'none';
      this.downloadDescriptionTarget.style.display = 'block';
    }
  }
}