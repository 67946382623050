import ApplicationController from './application_controller';
import { UAParser } from 'ua-parser-js'; 

export default class extends ApplicationController {
  static values = {
    url: String
  }
  static targets = [
    'title',
    'content'
  ]

  connect() {
    const parser = new UAParser();
    const OSName = parser.getOS().name;
    if (OSName === 'iOS' || OSName === 'Android') {
      this.titleTarget.textContent = 'Download the Creators app to get started';
      this.contentTarget.style.display = 'none';
      return;
    }
    if (this.hasUrlValue) {
      setTimeout(() => {
        window.location = this.urlValue;
      }, 1000);
    }
  }
}