import ApplicationController from './application_controller';

export default class extends ApplicationController {
  static targets = []

  connect() {
    if(!localStorage.getItem('hasSeenIntro')) {
      this.element.style.display = 'block';
    } else {
      this.element.remove();
    }
  }

  skip() {
    const startSlides = new CustomEvent('start-slides');
    window.dispatchEvent(startSlides);
    localStorage.setItem('hasSeenIntro', true);
    this.element.style.display = 'none';
  }
}