import ApplicationController from "./application_controller";

const CODEPOINT_THRESHOLD = 65535;
const VARIATION_SELECTOR = 65039;

export default class extends ApplicationController {
  static targets = [
    'text'
  ]

  stripInvalidChars(e) {
    const chars = Array.from(this.textTarget.value)
    this.textTarget.value = chars.filter((s, i) => {
      return s.codePointAt(0) < CODEPOINT_THRESHOLD && s.codePointAt(0) != VARIATION_SELECTOR && chars[i + 1]?.codePointAt(0) != VARIATION_SELECTOR
    }).join('')
  }

  trimWhitespace(e) {
    this.textTarget.value = this.textTarget.value.trim()
  }
}