import ApplicationController from './application_controller';

export default class extends ApplicationController {
  static values = {
    openText: String,
    closeText: String,
    cssClass: String
  }

  toggle(e) {
    e.preventDefault();
    const settingsMenu = document.querySelector('.settings__navigation');
    if(settingsMenu.classList.contains(this.cssClassValue)) {
      settingsMenu.classList.remove(this.cssClassValue);
      this.element.innerHTML = this.openTextValue;
    } else {
      settingsMenu.classList.add(this.cssClassValue);
      this.element.innerHTML = this.closeTextValue;
    }
  }
}