import ApplicationController from './application_controller';
import axios from 'axios';

export default class extends ApplicationController {
  static targets = [
    'form',
    'passwordInput',
    'submitButton',
    'error'
  ]
  static values = {
    sudosUrl: String
  }

  connect() {
    this.element[this.identifier] = this;
    this.passwordSuccess = false;
    this.detectAdBlock();
  }

  resetForm(showError = false, error = '') {
    this.submitButtonTarget.disabled = false;
    this.submitButtonTarget.classList.remove('button--loading');
    this.submitButtonTarget.innerHTML = 'Cancel now';
    if (showError) {
      this.errorTarget.classList.remove('hidden');
      this.errorTarget.innerText = error;
    }
  }

  bareMetricsCallbackSend() {
    console.log('Baremetrics success');
    this.submitButtonTarget.classList.add('button--loading');
    this.submitButtonTarget.disabled = true;
    this.submitButtonTarget.innerHTML = this.submitButtonTarget.dataset.disableWith;
  }

  async detectAdBlock() {
    this.adBlockEnabled = false;
    const baremetricsUrl = 'https://baremetrics-barecancel.baremetrics.com/js/application.js'
    try {
      await fetch(new Request(baremetricsUrl)).catch(_ => this.adBlockEnabled = true)
    } catch (e) {
      this.adBlockEnabled = true;
    } finally {
      console.log(`AdBlock Enabled: ${this.adBlockEnabled}`);
    }
  }

  cancelSubscription(e) {
    if (this.adBlockEnabled) {
      return;
    }

    e.preventDefault();

    // Don't resubmit this form if the password was correct
    if (this.passwordSuccess) {
      return;
    }

    const config = {
      withCredentials: true,
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      params: { password: this.passwordInputTarget.value }
    };
    axios.post(this.sudosUrlValue, null, config)
    .then(response => {
      this.passwordSuccess = true;
      this.submitButtonTarget.id = 'barecancel-trigger';
      this.resetForm();
      this.submitButtonTarget.click();
    })
    .catch(error => {
      console.log('Error setting password', error.response);
      this.resetForm(true, error.response.data);
    });
  }
}
