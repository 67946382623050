import 'core-js/es/object/has-own';
import { Controller } from '@hotwired/stimulus';
import Uppy from '@uppy/core';
import Dashboard from '@uppy/dashboard';
import ActiveStorageUpload from 'vendor/uppy-activestorage-upload';
import ImageEditor from '@uppy/image-editor';
import ColorThief from 'colorthief/dist/color-thief.mjs';
import { rgbToHex } from 'vendor/helpers';
import { Spinner } from 'spin.js';

export default class extends Controller {
  static targets = [
    'preview',
    'field',
    'fileInput',
    'useEventArtwork',
    'colorInput',
    'fileUploader'
  ]
  static values = {
    fieldName: {
      type: String,
      default: 'uppy-image'
    },
    directUploadUrl: String,
    imagePlaceholder: String,
    allowedFileTypes: {
      type: Array,
      default: ['.jpg', '.jpeg', '.png']
    },
    maxFileSize: {
      type: Number,
      default: 10 * 1024 * 1024 // 10mb
    },
    rounded: Boolean,
    isEditingFile: Boolean,
    hasUploaded: Boolean
  }

  connect() {
    this.boundOnFormSubmit = this.onFormSubmit.bind(this);
    this.fileInputTarget.form.addEventListener('submit', this.boundOnFormSubmit);

    const spinOpts = {
      lines: 8,
      length: 3,
      width: 16,
      radius: 40,
      scale: 0.2,
      color: '#000000',
      zIndex: 1000
    }
    this.spinner = new Spinner(spinOpts);

    this.uppy = new Uppy({
      autoProceed: false,
      allowMultipleUploads: false,
      allowMultipleUploadBatches: false,
      logger: this.debugLogger,
      meta: {
        blah:'blah'
      },
      restrictions: {
        maxNumberOfFiles: 1,
        maxFileSize: this.maxFileSizeValue,
        allowedFileTypes: this.allowedFileTypesValue
      }
    });

    this.uppy.use(ActiveStorageUpload, {
      directUploadUrl: this.directUploadUrlValue
    });

    this.uppy.on('upload', this.upload.bind(this));
    this.uppy.on('complete', this.complete.bind(this));
    this.uppy.on('error', this.error.bind(this));
    this.uppy.on('file-editor:start', this.fileEditorStart.bind(this));
    this.uppy.on('file-editor:complete', this.fileEditorComplete.bind(this));
    this.uppy.on('file-editor:cancel', this.fileEditorCancel.bind(this));
    this.uppy.on('file-added', this.fileAdded.bind(this));
    this.uppy.on('file-removed', this.fileRemoved.bind(this));
    this.uppy.on('restriction-failed', this.restrictionFailed.bind(this));
    this.uppy.on('thumbnail:generated', this.thumbnailGenerated.bind(this));

    this.uppy.use(Dashboard, {
      inline: true,
      target: this.element,
      proudlyDisplayPoweredByUppy: false,
      autoOpenFileEditor: true,
      hideUploadButton: true,
      showSelectedFiles: true,
      doneButtonHandler: null,
      animateOpenClose: false,
      theme: 'auto',
      autoOpen: 'imageEditor'
    });

    this.element.querySelector('.uppy-Dashboard').style.display = 'none';

    this.uppy.use(ImageEditor, {
      target: Dashboard,
      cropperOptions: {
        aspectRatio: 1,
        viewMode: 1,
        ready: () => {
          if (this.roundedValue) {
            const cropperViewBox = document.querySelector('.cropper-view-box');
            const cropperFace = document.querySelector('.cropper-face');
            if (cropperViewBox && cropperFace) {
              cropperViewBox.style.borderRadius = '50%';
              cropperFace.style.backgroundColor = 'inherit !important';
            }
          }

          this.spinner.spin(false);
        }
      },
      actions: {
        revert: true,
        rotate: true,
        granularRotate: true,
        flip: true,
        zoomIn: true,
        zoomOut: true,
        cropSquare: true,
        cropWidescreen: false,
        cropWidescreenVertical: false
      }
    });
  }

  disconnect() {
    this.fileInputTarget.form.removeEventListener('submit', this.onFormSubmit.bind(this))
  }

  onFormSubmit(event) {
    // Prevent Uppy trying to double upload
    if (this.hasUploadedValue) {
      return;
    }

    // Disable the form if editing or if uppy has files attached ready for upload
    if (this.uppy.getFiles().length) {
      event.preventDefault();
    }

    // If editing, first we save the file editor
    // On this callback complete (see fileEditorComplete()) we start the uppy upload
    if (this.isEditingFileValue) {
      event.preventDefault();
      event.stopImmediatePropagation();
      // if (confirm('You are currently editing an image. Click OK to upload or cancel to continue editing.')) {
      //   await this.uppy.getPlugin('Dashboard').saveFileEditor();
      //   this.uppy.upload();
      // }
      this.uppy.getPlugin('Dashboard').saveFileEditor();
    }

    // If we have files and not in editing mode, we can upload
    if (!this.isEditingFileValue && this.uppy.getFiles().length) {
      this.uppy.upload();
    }
  }

  selectImage(event) {
    if (!this.fileInputTarget.files && !this.fileInputTarget.files[0]) {
      return;
    }

    const file = event.target.files[0];
    const files = this.uppy.getFiles();
    if (files.length) {
      this.uppy.removeFile(files[0].id);
    }
    this.uppy.addFile(file);
  }

  hideFileEditor() {
    this.fileUploaderTarget.style.display = 'grid';
    this.element.querySelector('.uppy-Dashboard').style.display = 'none';
    this.fileInputTarget.value = [];
    this.isEditingFileValue = false;
  }

  thumbnailGenerated(file, preview) {
    console.log('[Uppy] - thumbnailGenerated:', file);
    const filesCard = this.element.querySelector('.uppy-Dashboard-FileCard');
    if (filesCard) filesCard.remove();
  }

  restrictionFailed(file, error) {
    console.log('[Uppy] - restrictionFailed file:', file);
    console.log('[Uppy] - restrictionFailed error:', error);
    alert(error);
    this.fileInputTarget.value = [];
  }

  fileAdded(file) {
    console.log('[Uppy] - fileAdded:', file);
    this.fileUploaderTarget.style.display = 'none';
  }

  fileRemoved(file) {
    console.log('[Uppy] - fileRemoved:', file);
    this.hideFileEditor();
  }

  fileEditorStart(file) {
    console.log('[Uppy] - fileEditorStart:', file);
    this.fileUploaderTarget.style.display = 'none';
    this.element.querySelector('.uppy-Dashboard').style.display = 'block';
    this.isEditingFileValue = true;

    // Scroll into view if needed
    const wrapper = document.querySelector('.wrapper');
    wrapper.scroll({ top: this.element.offsetTop, behavior: 'smooth' });
  }

  fileEditorComplete(file) {
    console.log('[Uppy] - fileEditorComplete:', file);
    this.isEditingFileValue = false;

    // This enables auto uploading on submission of form if editing
    this.uppy.upload();
  }

  fileEditorCancel(file) {
    console.log('[Uppy] - fileEditorCancel:', file);
    this.uppy.removeFile(file.id);
    this.hideFileEditor();
  }

  upload(uploadID, files) {
    console.log('[Uppy] - upload starting: ', files);
    this.dispatch('imageEditorUploadStart');
  }

  error(error) {
    console.log('[Uppy] - upload error: ', error);
    alert(error);
    this.dispatch('imageEditorUploadEnd');
    this.hasUploadedValue = false;
  }

  async complete(result) {
    console.log('[Uppy] - complete:', result);
    await result.successful.forEach(file => {
      this.appendFileField(file);
      this.generatePrimaryColor(file);
      this.setPreview(file);
    });
    this.hasUploadedValue = true;
    this.dispatch('imageEditorUploadEnd');
    this.fileInputTarget.form.requestSubmit();
  }

  generatePrimaryColor(file) {
    if (this.hasColorInputTarget && file.preview) {
      const colorThief = new ColorThief();
      const colorThiefImg = new Image();
      colorThiefImg.src = file.preview;
      const colorThiefColor = colorThief.getColor(colorThiefImg);
      if (colorThiefColor) {
        this.colorInputTarget.value = rgbToHex(...colorThiefColor);
      }
    }
  }

  setPreview(file) {
    if (this.hasPreviewTarget) {
      let src = (file.preview) ? file.preview : this.imagePlaceholderValue;
      this.previewTarget.src = src;
    }
  }

  appendFileField(file) {
    // If we already have a field target, update it's value
    // This prevents submitting multiple values for the same field
    if (this.hasFieldTarget) {
      this.fieldTarget.setAttribute('value', file.response.signed_id)
    } else {
      const field = document.createElement('input')
      field.setAttribute('type', 'hidden')
      field.setAttribute('data-uppy-target', 'field')
      field.setAttribute('name', this.fieldNameValue)
      field.setAttribute('data-pending-upload', true)
      field.setAttribute('value', file.response.signed_id)
      this.element.appendChild(field)
    }

    // If setting a recording from artwork to custom
    if (this.hasUseEventArtworkTarget) {
      this.useEventArtworkTarget.value = false;
    }
  }

  get debugLogger() {
    return {
      debug: (...args) => console.debug(`[Uppy]`, ...args),
      warn: (...args) => console.warn(`[Uppy]`, ...args),
      error: (...args) => console.error(`[Uppy]`, ...args),
    }
  }
}