import ApplicationController from './application_controller';

export default class extends ApplicationController {
  static targets = [
    'collectionCheckbox',
    'selectToggle',
    'moreToggle',
    'deleteButton'
  ]

  static values = {
    publicUrl: String,
    unlistedUrl: String,
  }

  connect() {
    if (this.hasMoreToggleTarget) {
      this.moreToggleTarget.setAttribute('disabled', true);
    }
    if (this.hasDeleteButtonTarget) {
      this.deleteButtonTarget.setAttribute('disabled', true);
    }

    document.addEventListener('turbo:frame-load', (event) => { 
      if (!event.target.matches('.collection-search__frame')) return;
      document.getElementsByClassName('wrapper')[0].scrollTo(0, 0);
    });
  }

  toggleCheckbox(event) {
    let setDisabled = true;
    if (event.currentTarget.checked) {
      this.moreToggleTarget.removeAttribute('disabled');
      if (this.hasDeleteButtonTarget) {
        this.deleteButtonTarget.removeAttribute('disabled');
      }
      this.selectToggleTarget.innerHTML = 'Select none';
    } else {
      this.collectionCheckboxTargets.forEach(checkbox => {
        if (checkbox.checked) {
          setDisabled = false;
        };
      });
      if (this.collectionCheckboxTargets.every((checkbox) => !checkbox.checked)) {
        setDisabled = true;
      }
      if (setDisabled) {
        this.selectToggleTarget.innerHTML = 'Select all';
        this.moreToggleTarget.setAttribute('disabled', true);
        if (this.hasDeleteButtonTarget) {
          this.deleteButtonTarget.setAttribute('disabled', true);
        }
      }
    }
  }

  selectAll(event) {
    if (event.currentTarget.innerHTML === 'Select all') {
      event.currentTarget.innerHTML = 'Select none';
      this.collectionCheckboxTargets.forEach(checkbox => {
        checkbox.checked = true;
        this.moreToggleTarget.removeAttribute('disabled');
        if (this.hasDeleteButtonTarget) {
          this.deleteButtonTarget.removeAttribute('disabled');
        }
      });
    } else {
      event.currentTarget.innerHTML = 'Select all';
      this.collectionCheckboxTargets.forEach(checkbox => {
        checkbox.checked = false;
        this.moreToggleTarget.setAttribute('disabled', true);
        if (this.hasDeleteButtonTarget) {
          this.deleteButtonTarget.setAttribute('disabled', true);
        }
      });
    }
  }

  setPublic(event) {
    event.target.form.action = this.publicUrlValue;
  }

  setUnlisted(event) {
    event.target.form.action = this.unlistedUrlValue;
  }
}
