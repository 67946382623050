import ApplicationController from './application_controller';

export default class extends ApplicationController {
  static targets = [ 'container', 'source' ]

  initialize() {

  }

  connect() {
    if (document.queryCommandSupported('copy')) {
      this.element.classList.add('clipboard--supported')
    }
  }

  disconnect() {
    
  }

  copy(event) {
    // We can't copy hidden inputs so reveal it and hide it instantly after copying
    const hiddenInputClass = 'clipboard--hide-input';
    if(this.containerTarget.classList.contains(hiddenInputClass)) {
      this.sourceTarget.type = 'text';
    }
    this.sourceTarget.select();
    event.target.classList.add('copied');
    document.execCommand('copy');
    if(this.containerTarget.classList.contains(hiddenInputClass)) {
      this.sourceTarget.type = 'hidden';
    }
    setTimeout(function() {
      event.target.classList.remove('copied');
    }, 3000);
  }
}