import ApplicationController from './application_controller';
import axios from 'axios';
import jstz from 'jstz';
import { timezones } from '../vendor/timezones';
import { getMetaValue } from '../vendor/helpers';

const detectedTimezone = timezones.toRails(Intl.DateTimeFormat().resolvedOptions().timeZone, false);
const promptDismissed = window.localStorage.getItem('dismissTimezonePrompt');

export default class extends ApplicationController {
  static targets = [
    'setButton',
    'cancelButton',
    'detectedTimezone'
  ]
  static values = { 
    url: String,
    storedTimezone: String
  }

  connect() {
    if (!promptDismissed && detectedTimezone && (detectedTimezone !== this.storedTimezoneValue)) {
      this.detectedTimezoneTarget.innerHTML = detectedTimezone;
      window.location.hash = 'timezone-modal';
    }
  }

  async setTimezone(event) {
    this.setButtonTarget.disabled = true;
    this.setButtonTarget.classList.add('button--loading');
    this.setButtonTarget.innerHTML = this.setButtonTarget.getAttribute('data-disable-with');

    // axios.defaults.headers.common['X-CSRF-TOKEN'] = getMetaValue('csrf-token');
    // axios.defaults.withCredentials = true;
    await axios.patch(this.urlValue, {
      timezone: detectedTimezone
    }, {
      headers: {
        'X-CSRF-TOKEN' : getMetaValue('csrf-token')
      },
      withCredentials: true
    })
    .then(response => {
      window.location.hash = '';
      history.replaceState(null, document.title, location.pathname + location.search);
      window.localStorage.setItem('dismissTimezonePrompt', true);
      window.location.reload();
    })
    .catch(error => {
      console.log('Error setting timezone', error);
      let errorMsg = error.response.data.error;
      let redirectUrl = error.response.data.redirect_url;

      this.element.innerHTML = '<div class="text--center"><p>There was an error setting your timezone: ' + errorMsg + '</p><button class="button button--link" type="button" onclick="location.href=\'' + redirectUrl + '\';">Close</button></div>';
      window.localStorage.setItem('dismissTimezonePrompt', true);
    });
  }

  close(event) {
    window.location.hash = '';
    history.replaceState(null, document.title, location.pathname + location.search);
    window.localStorage.setItem('dismissTimezonePrompt', true);
    window.location.reload();
  }
}