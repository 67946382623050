import ApplicationController from './application_controller';

export default class extends ApplicationController {
  static targets = [
    'source',
    'count'
  ]

  connect() {
    this.currentLength = this.sourceTarget.value.length;
    this.countTarget.innerHTML = this.currentLength;
  }

  input() {
    const value = event.target.value;
    const maxLength = this.sourceTarget.maxLength;
    this.currentLength = value.length;
    this.countTarget.innerHTML = this.currentLength;
    if(this.currentLength >= this.sourceTarget.maxLength) {
      this.sourceTarget.innerHTML = value.substring(0, maxLength);
      alert('You have reached the maximum number of characters.');
    }
  }
}