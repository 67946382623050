import ApplicationController from './application_controller';

export default class extends ApplicationController {
  static targets = ['modal'];
  static values = {
    src: String,
    isVisible: Boolean
  }

  connect() {
    const modal = document.getElementById('modal');
    if (modal && this.isVisibleValue && this.srcValue) {
      modal.src = this.srcValue;
    }
  }
}