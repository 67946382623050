export default class WebviewBridge {
  static loginUser() {
    this.frameRefresh();
  }

  static logoutUser() {
    this.frameRefresh();
  }

  static frameRefresh() {
    const frameRefreshElem = document.getElementById('frame-refresh');
    if (frameRefreshElem) {
      frameRefreshElem.setAttribute('src', '');
      frameRefreshElem.setAttribute('src', frameRefreshElem.dataset.src);
    }
  }
}