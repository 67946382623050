import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";

const application = Application.start();

const context = require.context("controllers", false, /.js$/)
const contextComponents = require.context("../../components", true, /_controller.js$/)

application.load(
  definitionsFromContext(context).concat(
    definitionsFromContext(contextComponents)
  )
);

application.debug = process.env.NODE_ENV === "development";

document.addEventListener("turbo:before-cache", () => {
  application.controllers.forEach((controller) => {
    if (typeof controller.teardown === "function") {
      controller.teardown();
    }
  });
});
