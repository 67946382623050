import ApplicationController from './application_controller';

export default class extends ApplicationController {
  static targets = ['modal'];

  connect() {
    document.body.classList.add("modal--open");
  }

  hideModal() {
    document.body.classList.remove("modal--open");
    this.element.parentElement.removeAttribute('src');
    this.modalTarget.remove();
  }

  submitEnd(e) {
    if (e.detail.success) {
      this.hideModal();
    }
  }

  closeWithKeyboard(e) {
    if (e.code == "Escape") {
      this.hideModal();
    }
  }

  closeBackground(e) {
    if (e && this.modalTarget.contains(e.target)) {
      return
    }
    this.hideModal();
  }
}

