import ApplicationController from './application_controller';

export default class extends ApplicationController {
  static targets = [
    'accordionItem'
  ]

  connect() {
    let rowActive = false;

    if (this.accordionItemTargets.length > 0) {
      this.accordionItemTargets.forEach(row => {
        if (row.classList.contains('accordion__item--active')) {
          rowActive = true;
          return;
        };
      });

      if (!rowActive) {
        this.accordionItemTargets[0].classList.add('accordion__item--active');
      }
    }
  }

  toggle(e) {
    this.accordionItemTargets.forEach(row => {
      if (row.contains(e.target)) {
        row.classList.add('accordion__item--active');
      } else {
        row.classList.remove('accordion__item--active');
      }
    });
  }
}