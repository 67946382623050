import ApplicationController from './application_controller';
import axios from 'axios';
import * as Turbo from '@hotwired/turbo';
import { UAParser } from 'ua-parser-js';

const MAX_SIZE = 1073741824;
const LEAVING_PAGE_MESSAGE_UPLOAD = "You have a recording upload in progress. If you leave, you will need to upload this recording again. Are you sure you want to exit this page?"
const LEAVING_PAGE_MESSAGE_NO_AUDIO = "This recording doesn't have an audio file yet. Click Cancel to add one, or OK to delete the recording."

export default class extends ApplicationController {
  static targets = [
    'progress',
    'progressBar',
    'progressCancel',
    'progressFileName',
    'fileInput',
    'dropZone',
    'dropZoneLabel',
    'dropZoneUploadButton',
    'errorMsg',
    'deleteForm'
  ]

  static values = {
    formData: Object,
    directUploadUrl: String,
    uploadCompleteUrl: String,
    redirectUrl: String,
    uploading: Boolean,
    deleting: Boolean,
    editing: Boolean,
    hasAudio: Boolean,
    redirecting: Boolean
  }

  connect() {
    const parser = new UAParser();
    const OSName = parser.getOS().name;
    if (OSName === 'iOS' || OSName === 'Android') {
      this.dropZoneLabelTarget.innerHTML = 'Choose audio file to upload';
      this.dropZoneUploadButtonTarget.innerHTML = 'Select file';
    }

    if (OSName === 'iOS') {
      // Force iOS to use file picker
      this.fileInputTarget.setAttribute('accept', 'application/octet-stream');
    }
  }

  dragOver() {
    this.dropZoneTarget.classList.add('drag-drop__zone--active');
  }

  dragLeave() {
    this.dropZoneTarget.classList.remove('drag-drop__zone--active');
  }

  async uploadRecording(e) {
    if (this.fileInputTarget.files && this.fileInputTarget.files[0]) {

      const file = this.fileInputTarget.files[0];

      if (!file.type.match('audio.*')) {
        e.preventDefault();
        this.fileInputTarget.value = '';
        alert('Please select an audio file');
        return;
      } else if (file.size > MAX_SIZE) {
        e.preventDefault();
        this.fileInputTarget.value = '';
        alert('Please select an audio file smaller than 1GB');
        return;
      }

      this.progressTarget.classList.remove('hide');
      this.progressFileNameTarget.innerHTML = file.name;
      this.dropZoneTarget.classList.add('drag-drop__zone--hidden');
      this.errorMsgTarget.classList.add('hidden');

      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      const config = {
        cancelToken: source.token,
        onUploadProgress: progressEvent => {
          const progressComplete = Math.ceil((progressEvent.loaded / progressEvent.total) * 100);
          this.progressBarTarget.value = progressComplete;
          this.uploadingValue = true;

          this.progressCancelTarget.addEventListener('click', (e) => {
            e.preventDefault();
            source.cancel();
            this.resetUpload('Recording upload cancelled');
          });
        }
      };
      await axios.put(this.directUploadUrlValue, file, config)
        .then(response => {
          console.log('Successful recording upload: ', response);
          this.onUploadSuccess();
        })
        .catch(error => {
          console.log('Error uploading recording:', error);
          this.resetUpload('Error uploading recording, please try again');
        });
    }
  }

  async onUploadSuccess() {
    this.progressFileNameTarget.innerHTML = 'Upload complete';
    this.uploadingValue = false;
    if (this.hasUploadCompleteUrlValue) {
      await axios.post(this.uploadCompleteUrlValue)
        .then(response => {
          console.log('Marking upload as complete', response);
          this.redirectingValue = true;
          Turbo.visit(this.redirectUrlValue, { action: 'replace' });
        })
        .catch(error => {
          console.log('Error marking upload as complete', error);
          this.resetUpload('Error uploading recording, please try again');
        });
    }
  }

  resetUpload(errorMsg) {
    this.uploadingValue = false;
    this.redirectingValue = false;
    this.deletingValue = false;
    this.dropZoneTarget.classList.remove('drag-drop__zone--hidden');
    this.dropZoneTarget.classList.remove('drag-drop__zone--active');
    this.progressBarTarget.value = 0;
    this.progressTarget.classList.add('hide');
    this.progressFileNameTarget.innerHTML = '';
    this.fileInputTarget.value = '';

    if (errorMsg) {
      this.errorMsgTarget.classList.remove('hidden');
      this.errorMsgTarget.innerText = errorMsg;
    }
  }

  delete() {
    this.deletingValue = true;
  }

  leavingPageClick(event) {
    if ((this.deletingValue || this.redirectingValue || this.editingValue)) {
      this.redirectingValue = false;
      this.deletingValue = false;
      return;
    }

    const leavingMsg = this.uploadingValue ? LEAVING_PAGE_MESSAGE_UPLOAD : LEAVING_PAGE_MESSAGE_NO_AUDIO;
    if (event.type === "turbo:before-visit") {
      if (!this.hasAudioValue || this.uploadingValue || this.fileInputTarget.files.length > 0) {
        event.preventDefault();
        if (window.confirm(leavingMsg)) {
          this.deleteFormTarget.submit();
        }
      }
    }
  }
}