import ApplicationController from './application_controller';
import axios from 'axios';
import Rails from '@rails/ujs';

export default class extends ApplicationController {
  static targets = [
    'artworkUploaded',
    'submitButton',
    'progress',
    'progressBar',
    'progressCancel',
    'progressFileName',
    'fileInput'
  ]
  static values = {
    formData: Object,
    url: String,
    host: String,
    uploadSucess: Boolean
  }

  connect() {
    this.buttonText = this.submitButtonTarget.innerHTML;
  }

  async submit(e) {

    // Return early if image was uploaded successfully
    // to avoid infinite Turbo submit loop
    // see below ajax success 
    if(this.uploadSuccessValue) {
      return;
    }

    if(this.fileInputTarget.files && this.fileInputTarget.files[0]) {
      e.preventDefault();
      this.submitButtonTarget.disabled = true;
      this.submitButtonTarget.classList.add('button--loading');
      this.submitButtonTarget.innerHTML = this.submitButtonTarget.getAttribute('data-disable-with');
      this.progressTarget.classList.remove('hide');
      this.progressFileNameTarget.innerHTML = this.fileInputTarget.files[0].name;

      let formData = new FormData();
      Object.keys(this.formDataValue).forEach(key => {
        formData.append(key, this.formDataValue[key]);
      });
      formData.append('file', this.fileInputTarget.files[0]);

      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      const config = {
        headers: {
          'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
          'Accept': 'application/xml, text/xml, */*'
        },
        cancelToken: source.token,
        onUploadProgress: progressEvent => {
          const progressComplete = Math.ceil((progressEvent.loaded / progressEvent.total) * 100);
          this.progressBarTarget.value = progressComplete;

          this.progressCancelTarget.addEventListener('click', (e) => {
            // console.log('Cancelling upload');
            source.cancel();
            this.resetUpload();
          });
        }
      };
      await axios.post(this.urlValue, formData, config)
      .then(response => {
        // console.log('Successful image upload: ', response);
        this.progressCancelTarget.style.display = 'none';
        if(this.hasArtworkUploadedTarget) {
          this.artworkUploadedTarget.value = '1';
        }
        setTimeout(() => {
          this.uploadSuccessValue = true;
          this.element.requestSubmit();
          this.resetUpload();
        }, 1500);
      })
      .catch(error => {
        console.log('Error uploading image:', error);
        this.resetUpload();
      });
    }
  }

  resetUpload() {
    this.uploadSuccessValue = false;
    this.progressBarTarget.value = 0;
    this.progressTarget.classList.add('hide');
    this.progressFileNameTarget.innerHTML = '';
    this.submitButtonTarget.disabled = false;
    this.submitButtonTarget.classList.remove('button--loading');
    this.submitButtonTarget.innerHTML = this.buttonText;
  }
}
