import ApplicationController from "./application_controller";

export default class TabsController extends ApplicationController {
  static targets = ["tab", "tabPanel"];
  static values = {
    defaultTab: String,
  };

  initialize() {
    this.showTab();
  }

  change(e) {
    this.index = this.tabTargets.indexOf(e.target.parentNode);
    this.showTab(this.index);
  }

  showTab() {
    if (this.tabPanelTargets?.length) {
      this.tabPanelTargets.forEach((el, i) => {
        if (i == this.index) {
          el.style.display = "block";
        } else {
          el.style.display = "none";
        }
      });
    }

    this.tabTargets.forEach((el, i) => {
      if (i == this.index) {
        el.classList.add("active");
      } else {
        el.classList.remove("active");
      }
    });
  }

  get index() {
    return parseInt(this.data.get("index"));
  }

  set index(value) {
    this.data.set("index", value);
    this.showTab();
  }
}
