import HighCharts from 'highcharts';
import ApplicationController from './application_controller';
import fetchDataSet from '../analytics/fetch_data_set';
import { loadingSpinner } from '../vendor/loadingSpinner';

export default class AnalyticsChartController extends ApplicationController {
  static values = {
    dataUrl: String,
    countUrl: String,
    key: String,
    countKey: String,
    title: String,
    recordingId: String,
    style: String
  };

  connect() {
    if (this.hasDataUrlValue) {
      this.updateChart(null, null, 'all_time');
    }
  }

  private;

  dateChange({ detail: { content } }) {
    if (this.hasDataUrlValue) {
      this.updateChart(
        null,
        null,
        content
      );
    }
  }

  updateChart(
    startDate = null,
    endDate = null,
    predefinedDateRange = null
  ) {
    (async () => {
      let count;
      const loader = loadingSpinner(this.element);
      const dataSet = await fetchDataSet({
        url: this.dataUrlValue,
        recordingId: this.hasRecordingIdValue ? this.recordingIdValue : null,
        startDate,
        endDate,
        predefinedDateRange
      });
      if (this.hasCountUrlValue) {
        count = await fetchDataSet({
          url: this.countUrlValue,
          recordingId: this.hasRecordingIdValue ? this.recordingIdValue : null,
          startDate,
          endDate,
          predefinedDateRange
        });
      }
      this.graphDataSet(dataSet, count);
      loader.stop();
    })();
  }

  graphDataSet(dataSet, count) {
    const dates = dataSet.data.map((element) => element.date);
    const data = dataSet.data.map(
      (element) => element[this.keyValue]
    );
    const chartCount = count?.data?.[this.countKeyValue] ? count.data[this.countKeyValue] : dataSet.data.reduce((acc, item) => {
      return acc + item[this.keyValue];
    }, 0);
    HighCharts.chart(this.element, {
      chart: {
        spacing: 16,
        style: {
          fontFamily: 'Montserrat'
        }
      },
      subtitle: {
        text: this.titleValue,
        floating: true,
        align: 'left',
        x: 40,
        y: 50,
        style: {
          color: '#000000',
          fontSize: '14px'
        }
      },
      title: {
        text: chartCount,
        floating: true,
        align: 'left',
        x: 40,
        y: 30,
        style: {
          color: '#000000',
          fontSize: '28px',
          fontWeight: 'bold'
        }
      },
      tooltip: {
        formatter: function () {
          return this.y;
        }
      },
      legend: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      series: [{
        type: 'area',
        fillColor: {
          linearGradient: [0, 0, 0, 300],
          stops: [
            [0, HighCharts.Color('#ed1c24').setOpacity(0.6).get('rgba')],
            [0.6, HighCharts.Color('#ed1c24').setOpacity(0.1).get('rgba')],
            [1, HighCharts.Color('#ed1c24').setOpacity(0).get('rgba')]
          ]
        },
        color: '#ed1c24',
        width: 4,
        marker: {
          radius: 0
        },
        data: data
      }],
      yAxis: {
        labels: {
          enabled: true
        },
        gridLineWidth: 0,
        title: {
          text: null
        },
        min: 0,
        minRange: 10,
        minTickInterval: 1
      },
      xAxis: this.styleValue === 'minimal' ? {
        visible: false
      } : {
        type: 'datetime',
        categories: dates
      }
    });
  }
}
