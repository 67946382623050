import ApplicationController from './application_controller';
import Cropper from 'cropperjs';

export default class extends ApplicationController {
  static targets = [
    'artworkUploaded',
    'fileInput',
    'remove',
    'preview',
    'submitRow',
    'changeImage',
    'imageUploader'
  ]
  static values = {
    cropping: Boolean,
    artworkUploaded: Boolean
  }

  connect() {
    if(this.croppingValue && this.cropper) {
      this.cropper = null;
    }
    if(this.artworkUploadedValue.value == 'true') {
      this.renderRemove()
    }
  }

  disconnect() {
    if(this.croppingValue && this.cropper) {
      this.cropper.destroy();
      this.cropper = null;
    }
  }

  change(event) {
    this.renderPreview(event);
  }

  renderPreview(e) {
    let file;
    let reader = new FileReader();
    const artworkW = document.getElementById('artwork_w');
    const artworkH = document.getElementById('artwork_h');
    const artworkX = document.getElementById('artwork_x1');
    const artworkY = document.getElementById('artwork_y1');
    const artworkType = document.getElementById('artwork_file_type');
    if(this.fileInputTarget.files && this.fileInputTarget.files[0]) {
      file = this.fileInputTarget.files[0];
      var fileSize = (file.size / 1000).toFixed(2);
      if(!file.type.match(/^image\/(jpg|jpeg|png)$/)) {
        e.preventDefault();
        this.fileInputTarget.value = '';
        alert('Please select a JPEG or PNG image.');
        return;
      } else if(file.size > (10*1024*1024)) {
        e.preventDefault();
        this.fileInputTarget.value = '';
        alert('Please select an image smaller than 10MB.');
        return;
      }
      reader.addEventListener('load', () => {
        this.previewTarget.classList.remove('hide');
        this.previewTarget.src = reader.result;
        this.previewTarget.addEventListener('load', () => {
          // If cropper instance exists 
          // destroy it before uploading new file
          if(this.croppingValue) {
            if(this.cropper) {
              this.cropper.destroy();
              this.cropper = null;
            }
            this.cropper = new Cropper(this.previewTarget, {
              aspectRatio: 1 / 1,
              crop: (e) => {
                artworkW.value = e.detail.width;
                artworkH.value = e.detail.height;
                artworkX.value = e.detail.x;
                artworkY.value = e.detail.y;
                artworkType.value = file.type;
              }
            });
          } else {
            if (artworkW && artworkH && artworkType) {
              artworkW.value = this.previewTarget.naturalWidth;
              artworkH.value = this.previewTarget.naturalHeight;
              artworkX.value = 0;
              artworkY.value = 0;
              artworkType.value = file.type;
            }
          }
          if (this.hasImageUploaderTarget) {
            this.imageUploaderTarget.style.display = 'none';
          }
          this.removeTarget.style.display = 'block';
          if(this.hasSubmitRowTarget) {
            this.submitRowTarget.classList.remove('hide');
          }
        });
      }, false);
      reader.readAsDataURL(file);
    }
  }

  renderRemove() {
    this.removeTarget.style.display = 'block';
  }

  remove(e) {
    e.preventDefault();
    this.fileInputTarget.value = '';
    this.removeTarget.style.display = 'none';
    this.previewTarget.src = '';
    this.previewTarget.classList.add('hide');
    this.changeImage();
    if(this.cropper) {
      this.cropper.destroy();
      this.cropper = null;
    }
    if(this.hasSubmitRowTarget) {
      this.submitRowTarget.classList.add('hide');
    }
  }

  changeImage() {
    this.imageUploaderTarget.style.display = 'flex';
    this.changeImageTarget.style.display = 'none';
  }
}
