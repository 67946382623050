import ApplicationController from './application_controller';
import Swiper from 'swiper/bundle';

export default class extends ApplicationController {
  static targets = [
    'container',
    'skip',
    'next',
    'prev',
    'start',
    'navigationButton'
  ]
  static values = {
    autoplay: Boolean,
    spaceBetween: Number,
    autoHeight: Boolean
  }

  connect() {
    this.swiper = new Swiper(this.containerTarget, {
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
      },
      spaceBetween: this.spaceBetweenValue ? this.spaceBetweenValue : 0,
      autoplay: {
        delay: 10000
      },
      autoHeight: this.autoHeightValue,
      centeredSlides: true,
      navigation: {
        nextEl: this.hasNextTarget ? this.nextTarget : 'swiper-next',
        prevEl: this.hasPrevTarget ? this.prevTarget : 'swiper-prev'
      },
      on: {
        transitionStart: () => {
          if (this.swiper.activeIndex === this.swiper.slides.length - 1) {
            if (this.hasSkipTarget && this.hasNextTarget) {
              this.skipTarget.style.display = 'none';
              this.nextTarget.style.display = 'none';
            }
            if (this.hasStartTarget) {
              this.startTarget.style.display = 'flex';
            }
          } else {
            if (this.hasSkipTarget && this.hasNextTarget) {
              this.skipTarget.style.display = 'block';
              this.nextTarget.style.display = 'block';
            }
            if (this.hasStartTarget) {
              this.startTarget.style.display = 'none';
            }
          }

          // Handle custom navigation buttons active state
          if (this.hasNavigationButtonTarget) {
            this.navigationButtonTargets.forEach((el, i) => {
              if (i === this.swiper.activeIndex) {
                el.classList.add('bg-black', 'text-white');
              } else {
                el.classList.remove('bg-black', 'text-white');
              }
            });
          }
        }
      }
    });

    if(!this.autoplayValue) {
      this.swiper.autoplay.stop();
    } else {
      this.swiper.autoplay.start();
    }
  }

  startSlides() {
    this.swiper.autoplay.start();
  }

  disconnect() {
    this.swiper.destroy()
    this.swiper = undefined
  }

  jumpToSlide(event) {
    this.swiper.slideTo(event.target.dataset.slide);
    if (this.hasNavigationButtonTarget) {
      this.navigationButtonTargets.forEach((el) => {
        el.classList.remove('bg-black', 'text-white');
      });
    }
    event.target.classList.add('bg-black', 'text-white');
  }
}
