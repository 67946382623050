import ApplicationController from './application_controller';

export default class extends ApplicationController {
  static targets = [
    'monthly',
    'annually',
    'featureList',
    'trialLink',
    'pricingToggleGroup'
  ];

  static values = {
    surveyLink: String,
  }

  connect() {
    this.toggleAnnual();
  }

  toggleAnnual() {
    if (!this.hasPricingToggleGroupTarget) return;
    this.pricingToggleGroupTarget.classList.add('pricing-toggle-annual');
    this.pricingToggleGroupTarget.classList.remove('pricing-toggle-monthly');
    this.monthlyTargets.forEach(item => {
      item.classList.add('hidden');
    });
    this.annuallyTargets.forEach(item => {
      item.classList.remove('hidden');
    });
  }

  toggleMonthly() {
    if (!this.hasPricingToggleGroupTarget) return;
    this.pricingToggleGroupTarget.classList.remove('pricing-toggle-annual');
    this.pricingToggleGroupTarget.classList.add('pricing-toggle-monthly');
    this.monthlyTargets.forEach(item => {
      item.classList.remove('hidden');
    });
    this.annuallyTargets.forEach(item => {
      item.classList.add('hidden');
    });
  }

  toggleFeaturesList() {
    this.featureListTargets.forEach(list => {
      list.classList.toggle('hidden');
    });
  }

  launchEnterpriseIntercom() {
    fbq('track', 'Lead', { content_name: 'Enterprise' });
    if (typeof Intercom === 'undefined') return;
    Intercom('startSurvey', this.surveyLinkValue);
  }
}