import { Spinner } from 'spin.js';

export function loadingSpinner(element) {
  const spinner = new Spinner({
    lines: 8,
    length: 0,
    width: 3,
    radius: 4
  }).spin(element);

  return spinner;
}