import ApplicationController from './application_controller';
import { Sortable } from 'sortablejs';
import { patch } from '@rails/request.js';

export default class extends ApplicationController {
  static values = {
    handleSelector: String,
  }

  connect() {
    const options = {
      scroll: true,
      forceAutoScrollFallback: false,
      delay: 200,
      touchStartThreshold: 5,
      delayOnTouchOnly: true,
      onEnd: this.onEnd.bind(this)
    }
    if (this.hasHandleSelectorValue) {
      options.handle = this.handleSelectorValue
    }
    Sortable.create(this.element, options);

    window.oncontextmenu = function (event) {
      event.preventDefault();
      event.stopPropagation();
      return false;
    };
  }

  onEnd(evt) {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const itemsPerPage = 20;
    const currentPage = urlParams.get('page') || 1;
    const startIndex = (currentPage - 1) * itemsPerPage;
    const rowOrderPosition = urlParams.has('page') ? (startIndex + evt.newIndex) : evt.newIndex;
    const body = {
      row_order_position: rowOrderPosition
    }
    patch(evt.item.dataset.sortableUrl, {
      body: JSON.stringify(body)
    })
  }
}