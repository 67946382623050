import ApplicationController from './application_controller';

export default class extends ApplicationController {
  static targets = []

  connect() {
    this.timeout = setTimeout(() => {
      this.remove();
    }, 2500);
  }

  disconnect() {
    clearTimeout(this.timeout);
    if(this.element) {
      this.element.remove();
    }
  }

  remove() {
    if(this.element) {
      this.element.classList.add('fade-out');
      setTimeout(() => {
        this.element.parentNode.removeChild(this.element);
        clearTimeout(this.timeout);
      }, 300);
    }
  }
}