export default class TurboBridge {
  static summaryHideRecordings(isRecording) {
    const recordingButton = document.querySelector(".tb-view-recordings");
    if (recordingButton && !isRecording) {
      recordingButton.remove();
    }
  }

  static loginUser() {
    const frameRefresh = document.getElementById("frame-refresh");
    if (frameRefresh) {
      frameRefresh.setAttribute("src", "");
      frameRefresh.setAttribute("src", frameRefresh.dataset.src);
    }
  }

  static logoutUser() {
    const frameRefresh = document.getElementById("frame-refresh");
    if (frameRefresh) {
      frameRefresh.setAttribute("src", "");
      frameRefresh.setAttribute("src", frameRefresh.dataset.src);
    }
  }

  static stopAudio() {
    const event = new CustomEvent("stop-audio");
    window.dispatchEvent(event);
  }

  static disableFormOnUpload(disableForm) {
    const editRecordingForm = document.getElementById("recording-form");
    if (!editRecordingForm) return;
    const formAction = editRecordingForm.dataset.actionUrl;
    if (disableForm) {
      editRecordingForm.removeAttribute("action");
      editRecordingForm.setAttribute("onsubmit", "event.preventDefault();");
    } else {
      editRecordingForm.setAttribute("action", formAction);
      editRecordingForm.removeAttribute("onsubmit");
    }
  }
  
  static setLocalRecordingsCount = (count) => {
    const localRecordingsButtonCount = document.querySelector(".tb-local-recordings-count");
    if (localRecordingsButtonCount) {
      localRecordingsButtonCount.innerHTML = count;
    }
  }

  static saveForm = () => {
    const submitButton = document.querySelector(
      ".button--submit"
    );
    if (submitButton) {
      submitButton.click();
    }
  };

  static saveRecordingDetails = () => {
    console.log("[javascriptHandler]: ", "Recording Edit - Save Changes");
    this.saveForm();
  };
}
