import ApplicationController from "./application_controller";
import fetchDataSet from "../analytics/fetch_data_set";
import { loadingSpinner } from "../vendor/loadingSpinner";

export default class AnalyticsCountController extends ApplicationController {
  static values = {
    dataUrl: String,
    title: String,
    recordingId: String,
    key: String
  };

  connect() {
    if (this.hasDataUrlValue) {
      this.showCount(null, null, 'all_time');
    }
  }

  private;

  dateChange({ detail: { content } }) {
    if (this.hasDataUrlValue) {
      this.showCount(null, null, content);
    }
  }

  showCount(startDate = null, endDate = null, predefinedDateRange = null) {
    (async () => {
      const loader = loadingSpinner(this.element);
      const count = await fetchDataSet({
        url: this.dataUrlValue,
        recordingId: this.hasRecordingIdValue ? this.recordingIdValue : null,
        startDate,
        endDate,
        predefinedDateRange,
      });
      loader.stop();
      
      this.element.innerHTML = `<strong class="analytics__number">${count?.data?.[this.keyValue] || 0}</strong> ${this.titleValue}`;
    })();
  }
}
