import ApplicationController from './application_controller';
import tinycolor from 'tinycolor2';

export default class extends ApplicationController {
  static targets = [
    'colorInput'
  ];

  static values = {
    themeColor: String
  }

  connect() {
    this.handleColorInput();
  }

  disconnect() {
    if(this.hasColorInputTarget) {
      this.colorInputTarget.closest('input').remove();
    }
  }

  handleColorInput() {
    if (this.hasColorInputTarget) {
      const textInput = document.createElement('input');
      const colorInput = this.colorInputTarget;
      const colorInputValue = this.colorInputTarget.value;
      textInput.value = colorInputValue;
      textInput.type = 'text';
      textInput.required = true;
      textInput.pattern = '^#(?:[0-9a-fA-F]{3}){1,2}$';
      textInput.maxLength = 7;
      textInput.className = 'form__input form__input--inline form__input--small';
      colorInput.insertAdjacentElement('afterend', textInput);
      colorInput.addEventListener('change', (e) => {
        textInput.value = e.target.value;
        this.checkColor(textInput);
      }, false);
      textInput.addEventListener('input', (e) => {
        colorInput.value = e.target.value;
        this.checkColor(textInput);
      }, false);
    }
  }

  checkColor(textInput) {
    const colorInput = this.colorInputTarget;
    const tinyColor = tinycolor(colorInput.value);
    const inputError = colorInput.closest('.form__row').querySelector('.form__error');
    
    if(!inputError) return;

    // Block white, as no text will show
    if (colorInput.value === '#ffffff' || tinyColor.getLuminance() > 0.95) {
      alert('White cannot be used as theme color.');
      textInput.value = this.themeColorValue;
      this.colorInputTarget.value = this.themeColorValue;
      return;
    }

    // Show an alert for really light colors
    if (tinyColor.getLuminance() > 0.7) {
      inputError.setAttribute('aria-hidden', false);
      inputError.classList.remove('hidden');
    } else {
      inputError.setAttribute('aria-hidden', true);
      inputError.classList.add('hidden');
    }
  }
}
