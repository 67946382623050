import ApplicationController from './application_controller';

export default class extends ApplicationController {
  handleChange(event) {
    const value = event.target.value;
    const turboFrame = this.element.closest('.per-page');
    const url = `${turboFrame.dataset.src}?per_page=${value}`;
    if (turboFrame) {
      turboFrame.src = url;
      history.pushState({}, '', url);
    }
  }  
}