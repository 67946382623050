import ApplicationController from './application_controller';

export default class extends ApplicationController {
  static targets = [
    'iframe',
    'iframePreview',
    'userText',
    'widgetTextarea',
    'sizeWideRadio',
    'sizeSquareRadio'
  ]
  static values = {
    baseUrl: String,
    reload: Boolean,
    channel: Boolean,
    userAttributes: Object
  }

  connect() {
    const embedUserSettings = JSON.parse(localStorage.getItem('embedUserSettings'));
    
    if(embedUserSettings && Object.keys(embedUserSettings).length) {
      this.userAttributesValue = embedUserSettings;
    } else {
      this.userAttributesValue = {
        size: 'wide'
      };
    }

    if (this.userAttributesValue.size === 'wide') {
      this.sizeWideRadioTarget.checked = true;
    } else {
      this.sizeSquareRadioTarget.checked = true;
    }
  }

  disconnect() {
    this.reloadValue = false;
  }

  changeSize(e) {
    this.userAttributesValue = { size: e.target.value };
    this.reloadValue = true;
  }

  displayiFrame() {
    let widgetURL = this.baseUrlValue;
    let iframeURL = this.baseUrlValue;

    const iframeHeight = this.userAttributesValue.size === 'square' ? '300px' : '200px';
    const iframeWidth = this.userAttributesValue.size === 'square' ? '300px' : '100%';
    this.iframeTarget.height = iframeHeight;
    this.iframeTarget.width = iframeWidth;

    if(Object.keys(this.userAttributesValue).length) {
      localStorage.setItem('embedUserSettings', JSON.stringify(this.userAttributesValue));
    }

    // Widget copy input
    let widgetInputText = `<iframe src="${widgetURL}" frameborder="0" scrolling="no" height="${iframeHeight}" width="${iframeWidth}"></iframe>` + this.userTextTarget.innerHTML;
    widgetInputText = widgetInputText.trim();
    this.widgetTextareaTarget.value = widgetInputText;
    this.widgetTextareaTarget.style.height = this.widgetTextareaTarget.scrollHeight + 'px';

    // iFrame preview
    let iframeUserAttributes = Object.assign({}, this.userAttributesValue);
    iframeUserAttributes.nocache = '1';
    iframeUserAttributes.isPreview = 'true';
    iframeUserAttributes.timestamp = Math.floor((Math.random()*1000000)+1);
    this.iframeTarget.src = iframeURL;
    if(this.reloadValue) {
      var iframeClone = this.iframeTarget.cloneNode(true);
      iframeClone.height = iframeHeight;
      iframeClone.width = iframeWidth;
      iframeClone.src = iframeURL;
      this.iframePreviewTarget.innerHTML = '';
      this.iframePreviewTarget.appendChild(iframeClone);
    }
  }

  userAttributesValueChanged() {
    this.displayiFrame();
  }
}
