import ApplicationController from './application_controller';
import { createPopper } from '@popperjs/core';

export default class extends ApplicationController {
  static targets = [
    'tooltip',
    'button'
  ]

  static values = {
    offset: Number,
    placement: String
  }

  connect() {
    this.popperInstance = createPopper(this.buttonTarget, this.tooltipTarget, {
      placement: this.placementValue,
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [-4, this.offsetValue],
          },
        },
      ]
    });
  }

  disconnect() {
    this.popperInstance.destroy();
  }

  show() {
    this.tooltipTarget.setAttribute('data-show', '');
    this.popperInstance.update();
  }

  hide() {
    this.tooltipTarget.removeAttribute('data-show');
  }
}

