import ApplicationController from './application_controller';

export default class extends ApplicationController {
  static targets = [
    'subtitle',
    'dailycap',
    'liveStreaming',
    'audioHostingAndContentManagement',
    'creatorsTools',
    'marketingDistribution',
    'support'
  ]

  connect() {
    this.createResizeObserver();
  }

  disconnect() {
    this.resizeObserver.disconnect(this.element);
  }

  createResizeObserver() {
    this.resizeObserver = new ResizeObserver(() => this.handleResize())
    this.resizeObserver.observe(this.element);
  }

  handleResize() {
    this.subtitleTargets.forEach(s => s.style.height = 'unset');
    const subtitleHeight = Math.max(...this.subtitleTargets.map(s => s.offsetHeight));
    this.subtitleTargets.forEach(s => s.style.height = subtitleHeight + 'px');

    this.dailycapTargets.forEach(d => d.style.height = 'unset');
    const dailycapHeight = Math.max(...this.dailycapTargets.map(d => d.offsetHeight));
    this.dailycapTargets.forEach(d => d.style.minHeight = dailycapHeight + 'px');

    // Price feature columns
    this.liveStreamingTargets.forEach(p => p.style.height = 'unset');
    const liveStreamingHeight = Math.max(...this.liveStreamingTargets.map(p => p.offsetHeight));
    this.liveStreamingTargets.forEach(p => p.style.minHeight = liveStreamingHeight + 'px');

    this.audioHostingAndContentManagementTargets.forEach(p => p.style.height = 'unset');
    const audioHostingAndContentManagementHeight = Math.max(...this.audioHostingAndContentManagementTargets.map(p => p.offsetHeight));
    this.audioHostingAndContentManagementTargets.forEach(p => p.style.minHeight = audioHostingAndContentManagementHeight + 'px');

    this.creatorsToolsTargets.forEach(p => p.style.height = 'unset');
    const creatorsToolsHeight = Math.max(...this.creatorsToolsTargets.map(p => p.offsetHeight));
    this.creatorsToolsTargets.forEach(p => p.style.minHeight = creatorsToolsHeight + 'px');

    this.marketingDistributionTargets.forEach(p => p.style.height = 'unset');
    const marketingDistributionHeight = Math.max(...this.marketingDistributionTargets.map(p => p.offsetHeight));
    this.marketingDistributionTargets.forEach(p => p.style.minHeight = marketingDistributionHeight + 'px');

    this.supportTargets.forEach(p => p.style.height = 'unset');
    const supportHeight = Math.max(...this.supportTargets.map(p => p.offsetHeight));
    this.supportTargets.forEach(p => p.style.minHeight = supportHeight + 'px');
  }
}