import ApplicationController from "./application_controller";
import mixpanel from "mixpanel-browser";
import { getMetaProperty, getMetaValue } from "../vendor/helpers";

export default class extends ApplicationController {
  static targets = ["pageLoad", "signupLink"];
  static values = {
    event: String,
    userId: Number,
    properties: Object,
    isListenerApp: Boolean,
  };

  initialize() {
    // Initialise Mixpanel with token
    const mixpanelToken = getMetaProperty("mixpanel:token");

    mixpanel.init(mixpanelToken, {
      loaded: async function (mixpanel) {
        if (typeof BigInt === "undefined") {
          // Safari < v14 does not support BigInt
          // and is breaking all the JS
          return;
        }

        let MixlrUtils = await import("../vendor/split_group_for_identifier");

        // Setup A/B Testing
        const mixpanelOriginalDistinctId = getMetaValue(
          "mixpanel:original-distinct-id"
        );
        let distinctId = mixpanelOriginalDistinctId
          ? mixpanelOriginalDistinctId
          : mixpanel.get_distinct_id();

        if (distinctId && typeof distinctId === "string") {
          distinctId = distinctId.replace(/\$device:/, "");
        }

        console.debug(
          "[Mixpanel] initialize/loaded() - mixpanel distinct id to use for split group:",
          distinctId
        );

        mixpanel.identify(distinctId);

        let split_group;
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        if (urlParams.has("split_group")) {
          split_group = urlParams.get("split_group");
        } else {
          split_group = MixlrUtils.default.SplitGroup.for(distinctId);
        }

        console.debug(
          "[Mixpanel] initialize/loaded() - split group",
          split_group
        );

        mixpanel.register({
          "Split Group": split_group,
        });
      },
    });

    if (this.hasUserIdValue) {
      mixpanel.identify(this.userIdValue);
    }
  }

  connect() {
    // Track page views
    if (!this.isListenerAppValue) {
      mixpanel.track("Page Viewed", {
        URL: window.location.pathname,
        ...this.propertiesValue,
      });
    }

    // Custom tracking events
    this.pageLoadTargets.forEach((elem) => {
      if (elem.hasAttribute("data-mixpanel-event") && this.hasPropertiesValue) {
        mixpanel.track(elem.dataset.mixpanelEvent, this.propertiesValue);
      }
    });
  }

  trackClickEvent(event) {
    if (
      event.currentTarget.hasAttribute("data-mixpanel-event") &&
      this.hasPropertiesValue
    ) {
      mixpanel.track(
        event.currentTarget.dataset.mixpanelEvent,
        this.propertiesValue
      );
    }
  }
}
