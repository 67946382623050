import ApplicationController from './application_controller';

export default class extends ApplicationController {
  static targets = [
    "closeButton"
  ]
  static values = {
    name: String
  }

  isNotificationDismissed() {
    let notificationDismissed = this.existingNotifications[this.nameValue];

    if (notificationDismissed) {
      new Date(notificationDismissed) <= new Date;
    } else {
      return false;
    }
  }

  connect() {
    this.localStorageKey = "creatorNotifications";
    this.existingNotifications = JSON.parse(window.localStorage.getItem(this.localStorageKey));

    // initialize empty store
    if (this.existingNotifications === null) {
      this.existingNotifications = {};
      this.store();
    };

    if (this.isNotificationDismissed() === false) {
      this.scope.element.classList.remove('no-display');
    }
  }

  close(_) {
    this.existingNotifications[this.nameValue] = new Date;
    this.store();
    this.scope.element.classList.add('no-display');
  }

  store() {
    window.localStorage.setItem(this.localStorageKey, JSON.stringify(this.existingNotifications));
  }
}
