import ApplicationController from './application_controller';

export default class extends ApplicationController {
  static targets = [
    'hide'
  ]

  connect() {
    // If embedded in an iframe, add data-iframe-target="hide"
    // to elements we want to hide from the views
    if (window !== window.parent) {
      if (this.hasHideTarget) {
        this.hideTargets.forEach(item => {
          item.style.display = 'none';
        });
      }
    }
  }
}