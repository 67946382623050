import ApplicationController from './application_controller';

export default class extends ApplicationController {
  static targets = [ 
    'container',
    'nav',
    'detail',
    'button'
  ]

  initialize() {
    this.boundHandleClickOutside = this.handleClickOutside.bind(this);
  }

  connect() {
    document.addEventListener('click', this.boundHandleClickOutside);
  }

  disconnect() {
    document.removeEventListener('click', this.boundHandleClickOutside);
  }

  toggleNav() {
    this.buttonTarget.classList.toggle('active');
    this.containerTarget.classList.toggle('nav--open');
  }

  handleClickOutside(event) {
    if(!this.buttonTarget.contains(event.target)) {
      if(this.containerTarget.classList.contains('nav--open')) {
        this.containerTarget.classList.remove('nav--open');
        this.buttonTarget.classList.remove('active');
      }
    }
  }
}