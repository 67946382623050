const timezones = {
  toRails: function(key, utcDefault=true) {

    const IANA_TO_RAILS = {
      "Pacific/Midway": ["International Date Line West", "Midway Island"],
      "Pacific/Pago_Pago": ["Samoa"], // This becomes American Samoa in later Rails versions
      "Pacific/Honolulu": ["Hawaii"],
      "America/Adak": ["Hawaii"],
      "America/Anchorage": ["Alaska"],
      "Pacific/Gambier": ["Alaska"],
      "Pacific/Marquesas": ["Pacific Time (US & Canada)"],
      "Pacific/Pitcairn": ["Pacific Time (US & Canada)"],
      "America/Los_Angeles": ["Pacific Time (US & Canada)"],
      "America/Tijuana": ["Tijuana"],
      "America/Denver": ["Mountain Time (US & Canada)"],
      "America/Phoenix": ["Arizona"],
      "America/Chihuahua": ["Chihuahua"],
      "America/Mazatlan": ["Mazatlan"],
      "America/Chicago": ["Central Time (US & Canada)"],
      "America/Regina": ["Saskatchewan"],
      "America/Mexico_City": ["Guadalajara", "Mexico City"],
      "America/Monterrey": ["Monterrey"],
      "America/Guatemala": ["Central America"],
      "America/New_York": ["Eastern Time (US & Canada)"],
      "Pacific/Easter": ["Eastern Time (US & Canada)"],
      "America/Indiana/Indianapolis": ["Indiana (East)"],
      "America/Bogota": ["Bogota"],
      "America/Lima": ["Lima", "Quito"],
      "America/Halifax": ["Atlantic Time (Canada)"],
      "America/Santo_Doming": ["Atlantic Time (Canada)"],
      "America/Caracas": ["Caracas"],
      "America/La_Paz": ["La Paz"],
      "America/Santiago": ["Santiago"],
      "America/St_Johns": ["Newfoundland"],
      "America/Sao_Paulo": ["Brasilia"],
      "America/Asuncion": ["Brasilia"],
      "America/Montevideo": ["Brasilia"],
      "America/Argentina/Buenos_Aires": ["Buenos Aires"],
      "America/Guyana": ["Georgetown"],
      "America/Godthab": ["Greenland"],
      "Atlantic/South_Georgia": ["Mid-Atlantic"],
      "America/Noronha": ["Mid-Atlantic"],
      "Atlantic/Azores": ["Azores"],
      "Atlantic/Cape_Verde": ["Cape Verde Is."],
      "Europe/Dublin": ["Edinburgh"], // This becomes Dublin in later Rails versions
      "Europe/Lisbon": ["Lisbon"],
      "Europe/London": ["London"],
      "Africa/Casablanca": ["Casablanca"],
      "Africa/Monrovia": ["Monrovia"],
      "UTC": ["UTC"],
      "Europe/Belgrade": ["Belgrade"],
      "Europe/Bratislava": ["Bratislava"],
      "Europe/Budapest": ["Budapest"],
      "Europe/Ljubljana": ["Ljubljana"],
      "Europe/Prague": ["Prague"],
      "Europe/Sarajevo": ["Sarajevo"],
      "Europe/Skopje": ["Skopje"],
      "Europe/Warsaw": ["Warsaw"],
      "Europe/Zagreb": ["Zagreb"],
      "Europe/Brussels": ["Brussels"],
      "Europe/Copenhagen": ["Copenhagen"],
      "Europe/Madrid": ["Madrid"],
      "Europe/Paris": ["Paris"],
      "Europe/Amsterdam": ["Amsterdam"],
      "Europe/Berlin": ["Berlin", "Bern"],
      "Europe/Rome": ["Rome"],
      "Europe/Stockholm": ["Stockholm"],
      "Europe/Vienna": ["Vienna"],
      "Africa/Algiers": ["West Central Africa"],
      "Africa/Lagos": ["West Central Africa"],
      "Africa/Windhoek": ["West Central Africa"],
      "Europe/Bucharest": ["Bucharest"],
      "Africa/Cairo": ["Cairo"],
      "Europe/Helsinki": ["Helsinki"],
      "Europe/Kiev": ["Kyiv"], // This becomes Kyev in later Rails versions
      "Europe/Riga": ["Riga"],
      "Europe/Sofia": ["Sofia"],
      "Europe/Tallinn": ["Tallinn"],
      "Europe/Vilnius": ["Vilnius"],
      "Europe/Athens": ["Athens"],
      "Europe/Istanbul": ["Istanbul"],
      "Europe/Minsk": ["Minsk"],
      "Asia/Jerusalem": ["Jerusalem"],
      "Asia/Beirut": ["Jerusalem"],
      "Africa/Harare": ["Harare"],
      "Africa/Johannesburg": ["Pretoria"],
      "Europe/Moscow": ["Moscow", "St. Petersburg", "Volgograd"],
      "Asia/Kuwait": ["Kuwait"],
      "Asia/Riyadh": ["Riyadh"],
      "Africa/Nairobi": ["Nairobi"],
      "Asia/Baghdad": ["Baghdad"],
      "Asia/Tehran": ["Tehran"],
      "Asia/Muscat": ["Abu Dhabi", "Muscat"],
      "Asia/Dubai": ["Abu Dhabi"],
      "Asia/Baku": ["Baku"],
      "Asia/Tbilisi": ["Tbilisi"],
      "Asia/Yerevan": ["Yerevan"],
      "Asia/Kabul": ["Kabul"],
      "Asia/Yekaterinburg": ["Ekaterinburg"],
      "Asia/Karachi": ["Islamabad", "Karachi"],
      "Asia/Tashkent": ["Tashkent"],
      "Asia/Kolkata": ["Chennai", "Kolkata", "Mumbai", "New Delhi"],
      "Asia/Kathmandu": ["Kathmandu"],
      "Asia/Dhaka": ["Astana", "Dhaka"],
      "Asia/Colombo": ["Sri Jayawardenepura"],
      "Asia/Almaty": ["Almaty"],
      "Asia/Novosibirsk": ["Novosibirsk"],
      "Asia/Omsk": ["Novosibirsk"],
      "Asia/Rangoon": ["Rangoon"],
      "Asia/Bangkok": ["Bangkok", "Hanoi"],
      "Asia/Jakarta": ["Jakarta"],
      "Asia/Krasnoyarsk": ["Krasnoyarsk"],
      "Asia/Shanghai": ["Beijing"],
      "Asia/Chongqing": ["Chongqing"],
      "Asia/Hong_Kong": ["Hong Kong"],
      "Asia/Urumqi": ["Urumqi"],
      "Asia/Kuala_Lumpur": ["Kuala Lumpur"],
      "Asia/Singapore": ["Singapore"],
      "Asia/Taipei": ["Taipei"],
      "Australia/Perth": ["Perth"],
      "Asia/Irkutsk": ["Irkutsk"],
      "Asia/Ulaanbaatar": ["Ulaan Bataar"],
      "Asia/Seoul": ["Seoul"],
      "Asia/Tokyo": ["Osaka", "Sapporo", "Tokyo"],
      "Asia/Yakutsk": ["Yakutsk"],
      "Australia/Darwin": ["Darwin"],
      "Australia/Adelaide": ["Adelaide"],
      "Australia/Melbourne": ["Canberra", "Melbourne"],
      "Australia/Lord_Howe": ["Melbourne"],
      "Australia/Sydney": ["Sydney"],
      "Australia/Eucla": ["Sydney"],
      "Australia/Brisbane": ["Brisbane"],
      "Australia/Hobart": ["Hobart"],
      "Asia/Vladivostok": ["Vladivostok"],
      "Pacific/Guam": ["Guam"],
      "Pacific/Port_Moresby": ["Port Moresby"],
      "Asia/Magadan": ["Magadan"],
      "Pacific/Guadalcanal": ["Solomon Is."],
      "Pacific/Noumea": ["New Caledonia"],
      "Pacific/Fiji": ["Fiji"],
      "Asia/Kamchatka": ["Kamchatka"],
      "Pacific/Majuro": ["Marshall Is."],
      "Pacific/Auckland": ["Auckland", "Wellington"],
      "Pacific/Norfolk": ["Auckland"],
      "Pacific/Tongatapu": ["Nuku'alofa"],
      "Pacific/Chatham": ["Nuku'alofa"],
      "Pacific/Apia": ["Nuku'alofa", "Samoa"],
      "Pacific/Kiritimati": ["Nuku'alofa"],
      "Pacific/Fakaofo": ["Tokelau Is."]
    }

    if (IANA_TO_RAILS[key]) {
      return IANA_TO_RAILS[key][0];
    } else {
      if (utcDefault) {
        return IANA_TO_RAILS.UTC;
      } else {
        return null;
      }
    }
  }
};

export { timezones };
