import { getMetaProperty } from "../vendor/helpers";
import { v4 as uuidv4 } from "uuid";

const fetchDataSet = async ({
  url,
  recordingId = null,
  startDate = null,
  endDate = null,
  predefinedDateRange = null,
  recordingIds = null,
}) => {
  /**
   * +predefinedDateRange+, if present, needs to have one of the
   * string values:
   *
   * - last_7_days
   * - last_30_days
   * - last_6_months
   * - last_1_year
   * - all_time
   *
   * When it is populated, the +startDate+ and +endDate+ are ignored.
   */
  let params = {};
  if (recordingIds) {
    params = {
      recording_ids: recordingIds,
    };
  } else {
    params = {
      recording_id: recordingId,
      start_date: startDate,
      end_date: endDate,
      predefined_date_range: predefinedDateRange,
    };
  }
  const queryString = new URLSearchParams(params).toString();
  if (queryString) {
    url = `${url}?${queryString}`;
  }
  const jwt = getMetaProperty("analytics:jwt");
  const requestId = uuidv4();
  const response = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${jwt}`,
      "x-amzn-RequestId": requestId,
    },
  });
  const jsonData = await response.json();
  return jsonData;
};

export default fetchDataSet;
