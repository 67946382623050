import ApplicationController from './application_controller';
import { post } from '@rails/request.js';

export default class extends ApplicationController {
  static values = {
    tokenUrl: String,
    generateTokenUrl: String,
    redirectUrl: String
  };

  async generateToken(event) {
    if (!this.generateTokenUrlValue) return;
    event.preventDefault();

    const response = await post(this.generateTokenUrlValue, {
      responseKind: 'json'
    });

    if (response.ok) {
      const responseBody = await response.json;
      const url = `${this.tokenUrlValue}?token=${responseBody.token}&redirect_url=${this.redirectUrlValue}`;
      window.open(url, '_blank');
    } else {
      window.open(this.redirectUrlValue, '_blank');
    }
  }
}