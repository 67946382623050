import ApplicationController from "./app_intro_controller";
import fetchDataSet from "../analytics/fetch_data_set";
import { loadingSpinner } from '../vendor/loadingSpinner';

export default class AnalyticsIndexTableController extends ApplicationController {
  static values = {
    dataUrl: String,
    recordingIds: Array
  };

  static targets = [
    'plays',
    'listens'
  ]

  connect() {
    if (this.hasRecordingIdsValue) {
      this.getAllRecordings(this.recordingIdsValue);
    }
  }

  private;

  getAllRecordings(recordingIds = null) {
    (async () => {
      this.playsTargets.forEach((el) => loadingSpinner(el));
      this.listensTargets.forEach((el) => loadingSpinner(el));
      const dataSet = await fetchDataSet({
        url: this.dataUrlValue,
        recordingIds: recordingIds
      });
      if (dataSet?.data.length) {
        this.playsTargets.forEach((el) => {
          const dataSetId = Number(el.dataset.recordingId);
          const recording = dataSet.data.find(r => r.recordingId === dataSetId);
          el.innerHTML = recording ? recording.playsCount : 0;
        });
        this.listensTargets.forEach((el) => {
          const dataSetId = Number(el.dataset.recordingId);
          const recording = dataSet.data.find(r => r.recordingId === dataSetId);
          el.innerHTML = recording ? recording.uniqueListenersCount : 0;
        });
      }
    })();
  }
}
