import ApplicationController from './application_controller';

export default class extends ApplicationController {
  static targets = [
    'source'
  ]

  toggleVisibility(e) {
    e.preventDefault();
    if(event.currentTarget.classList.contains('active')) {
      this.sourceTarget.type = 'password';
      event.currentTarget.classList.remove('active');
    } else {
      this.sourceTarget.type = 'text';
      event.currentTarget.classList.add('active');
    }
  }
}