import ApplicationController from './application_controller';

export default class extends ApplicationController {
  static values = {
    autoScroll: Boolean,
    turboAction: String
  }

  connect() {
    this.element.addEventListener('turbo:submit-end', (event) => {
      this.next(event)
    })
  }

  next(event) {
    const action = this.turboActionValue ? this.turboActionValue : 'advance';
    if (event.detail.success) {
      if (this.autoScrollValue) {
        Turbo.visit(event.detail.fetchResponse.response.url.split('?')[0], { action: action })
      } else {
        Turbo.visit(event.detail.fetchResponse.response.url, { action: action })
      }
    }
  }
}