import ApplicationController from './application_controller';

export default class extends ApplicationController {
  connect() {
    this.validateFields();
  }

  validateFields() {
    const requiredInputSelectors = this.element.querySelectorAll('[required]');
    if (!requiredInputSelectors) return;    
    
    requiredInputSelectors.forEach(input => {
      
      const inputLabel = input.labels[0];
      const inputError = input.closest('.form-group').querySelector('.form-error');
      const inputErrorClass = 'border-red-500';
      const inputLabelErrorClass = 'text-red-500';

      input.addEventListener('input', function (e) {
        if (input.value.length > 0 && input.validity.valid) {
          input.classList.remove(inputErrorClass);
          inputLabel.classList.remove(inputLabelErrorClass);
          if (inputError) {
            inputError.setAttribute('aria-hidden', true);
            inputError.classList.add('hidden');
          }
        }
      });

      input.addEventListener('blur', function (e) {
        if (!input.validity.valid) {
          input.classList.add(inputErrorClass);
          inputLabel.classList.add(inputLabelErrorClass);
          if (inputError) {
            inputError.setAttribute('aria-hidden', false);
            inputError.classList.remove('hidden');
          }
        }
      });

      input.addEventListener('invalid', function (e) {
        e.preventDefault();
        inputLabel.classList.add(inputErrorClass);
        input.classList.add(inputLabelErrorClass);
        if (inputError) {
          inputError.setAttribute('aria-hidden', false);
          inputError.classList.remove('hidden');
        }
      });
    });
  }
}