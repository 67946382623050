import ApplicationController from './application_controller';
import fetchDataSet from '../analytics/fetch_data_set';

export default class AnalyticsTableController extends ApplicationController {
  static values = {
    dataUrl: String,
    key: String,
    recordingId: String
  };

  connect() {
    if (this.hasDataUrlValue) {
      this.updateTable(null, null, 'all_time');
    }
  }

  private;

  dateChange({ detail: { content } }) {
    if (this.hasDataUrlValue) {
      this.updateTable(
        null,
        null,
        content
      );
    }
  }

  updateTable(
    startDate = null,
    endDate = null,
    predefinedDateRange = null
  ) {
    (async () => {
      const dataSet = await fetchDataSet({
        url: this.dataUrlValue,
        recordingId: this.hasRecordingIdValue ? this.recordingIdValue : null,
        startDate,
        endDate,
        predefinedDateRange
      });
      this.renderTable(dataSet);
    })();
  }

  renderTable(dataSet) {
    const tableRow = dataSet.data.map(function (row) {
      return`<tr>
        <td>${row[0]}</td>
        <td>${row[1]}</td>
      </tr>`;
    })
    this.element.innerHTML = `
      <table>
        <tbody>
          ${tableRow}
        </tbody>
      </table>
    `;
  }
}
