import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = [
    "fileInput",
    "remove",
    "preview",
    "submitRow",
    "useEventArtwork",
  ];
  static values = {
    artworkAttached: Boolean,
    artworkUrl: String,
    eventArtworkUrl: String,
  };

  initialize() {
    let previewSrc;

    if (this.artworkAttachedValue) {
      previewSrc = this.artworkUrlValue;
      this.removeTarget.style.display = "block";
      this.renderPreview(previewSrc);
    } else if (this.eventArtworkUrlValue) {
      previewSrc = this.eventArtworkUrlValue;
      this.renderPreview(previewSrc);
    } else {
      this.previewTarget.style.display = "none";
    }
  }

  artworkAttachedValueChanged(value, previousValue) {
    if (value == true) {
      this.removeTarget.style.display = "block";
    } else {
      this.removeTarget.style.display = "none";
    }
  }

  change(event) {
    if (event.target.files.length <= 0) {
      return;
    }

    event.preventDefault();

    let file = event.target.files[0];
    let validationError = this.validateFile(file);

    if (validationError !== undefined) {
      this.fileInputTarget.value = "";
      alert(validationError);
      return;
    }

    let reader = new FileReader();
    reader.addEventListener(
      "load",
      () => {
        this.renderPreview(reader.result);
      },
      false
    );
    reader.readAsDataURL(file);
    this.artworkAttachedValue = true;
    if (this.hasUseEventArtworkTarget) {
      this.useEventArtworkTarget.value = false;
    }
  }

  validateFile(file) {
    let acceptedTypes = this.fileInputTarget.accept.split(",");

    console.debug(
      "acceptedTypes",
      acceptedTypes,
      "fileType",
      file.type,
      "file",
      file
    );

    if (!acceptedTypes.includes(file.type)) {
      return `Please select a JPEG or PNG image. (acceptedTypes = ${acceptedTypes}, file.type = ${file.type})`;
    } else if (file.size > 10 * 1024 * 1024) {
      return "Please select an image smaller than 10MB.";
    }
  }

  renderPreview(previewSrc) {
    if (this.previewTarget) {
      this.previewTarget.src = previewSrc;
      this.previewTarget.style.display = "block";
    }
  }

  remove(e) {
    e.preventDefault();
    this.previewTarget.style.display = "none";
    this.previewTarget.src = this.eventArtworkUrlValue;
    this.fileInputTarget.value = "";
    this.artworkAttachedValue = false;
    if (this.hasUseEventArtworkTarget) {
      this.useEventArtworkTarget.value = true;
    }
  }
}
