import ApplicationController from './application_controller';
import axios from 'axios';

export default class extends ApplicationController {
  static targets = [
    'privacySettings',
    'hiddenRadio',
    'enabledRadio',
    'generateButton',
    'accessCodeLink',
  ]

  initialize() {
    this.accessCode = document.querySelector('#first_livepage_access_code');
    this.boundGenerateAccessCode = this.generateAccessCode.bind(this);
  }

  connect() {
    if(this.enabledRadioTarget.checked) {
      this.hiddenRadioTarget.setAttribute('data-readonly', true);
    }
    if(!this.hiddenRadioTarget.checked) {
      this.privacySettingsTarget.style.display = 'none';
    }
  }

  clickHiddenOnPlatform(event) {
    if(event.currentTarget.getAttribute('data-readonly') === 'true') {
      event.preventDefault();
    }
  }

  changeHiddenOnPlatform(event) {
    if(!event.currentTarget.checked) {
      this.enabledRadioTarget.checked = false;
      this.privacySettingsTarget.style.display = 'none';
    }
  }

  changeAccesscodeEnabled(event) {
    if(event.currentTarget.checked) {
      if(this.accessCode.value === '') {
        this.boundGenerateAccessCode();
      }
      this.privacySettingsTarget.style.display = 'block';
      this.hiddenRadioTarget.setAttribute('data-readonly', true);
      this.hiddenRadioTarget.checked = true;
    } else {
      this.privacySettingsTarget.style.display = 'none';
      this.hiddenRadioTarget.setAttribute('data-readonly', false);
    }
  }

  async generateAccessCode(event) {
    const ajaxURL = this.generateButtonTarget.getAttribute('data-href');
    if(ajaxURL) {
      await axios.get(ajaxURL)
      .then(response => {
        const password = response.data.password;
        this.accessCode.value = password;
      })
      .catch(error => {
        console.log('Error getting access code password:', error);
      });
    }
  }

  changeLinkType(event) {
    const value = event.currentTarget.value;
    const input = this.accessCodeLinkTarget;
    input.value = '';
    
    if(value === 'Link') {
      input.type = 'url';
      input.placeholder = 'https://';
    } else {
      input.type = 'email';
      input.placeholder = 'Enter your email address';
    }
  }
}
