import ApplicationController from './application_controller';
import { DateTime } from 'luxon';

export default class extends ApplicationController {
  static targets = [
    'newEventForm',
    'selectLocalStartsAtString',
    'selectLocalEndsAtString',
    'selectLocalStartsOnString',
    'selectLocalEndsOnString'
  ]

  connect() {
    // Only on the new event form 
    // set the start/end date to now + 1 hour
    if(this.hasNewEventFormTarget) {
      const now = DateTime.now();
      const rounded = now.set({ minutes: Math.round((now.minute / 10)) * 10 + 10 });
      const startDateTime = rounded.toLocaleString(DateTime.TIME_24_SIMPLE);
      const endDateTime = rounded.plus({ hours: 1 }).toLocaleString(DateTime.TIME_24_SIMPLE);
      this.selectLocalStartsAtStringTarget.value = startDateTime;
      this.selectLocalEndsAtStringTarget.value = endDateTime;
    }
  }

  changeLocalStartsAtString(e) {
    const currentVal = DateTime.fromISO(e.currentTarget.value);
    this.selectLocalEndsAtStringTarget.value = currentVal.plus({ hours: 1 }).toLocaleString(DateTime.TIME_24_SIMPLE);
  }

  changeLocalStartsOnString(e) {
    const currentVal = e.currentTarget.value;
    this.selectLocalEndsOnStringTarget.value = currentVal;
  }
}