import ApplicationController from './application_controller';

export default class extends ApplicationController {
  static targets = []

  initialize() {
    this.boundBlockDragDrop = this.blockDragDrop.bind(this);
  }

  connect() {
    document.addEventListener('dragenter', this.boundBlockDragDrop);
    document.addEventListener('dragover', this.boundBlockDragDrop);
    document.addEventListener('drop', this.boundBlockDragDrop);
  }

  disconnect() {
    document.removeEventListener('click', this.boundBlockDragDrop);
  }

  blockDragDrop(event) {
    if(event.target.getAttribute('type') !== 'file') {
      event.preventDefault();
      event.dataTransfer.effectAllowed = 'none';
      event.dataTransfer.dropEffect = 'none';
    }
  }
}