import ApplicationController from './application_controller';

export default class extends ApplicationController {
  static targets = [
    'searchTypeField'
  ]

  initialize() {
  }

  connect() {
  }

  disconnect() {
  }

  setType(event) {
    if (this.hasSearchTypeFieldTarget) {
      this.searchTypeFieldTarget.value = event.params.type;
      this.element.submit();
    }
  }

  categoryChange() {
    this.element.submit();
  }
}