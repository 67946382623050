import ApplicationController from './application_controller';
import { DirectUpload } from '@rails/activestorage';

const LEAVING_PAGE_MESSAGE = "You are attempting to leave this page and you are uploading images. If you leave, you will need to reupload these files.  Are you sure you want to exit this page?"

export default class extends ApplicationController {
  static targets = []
  static values = { 
    uploading: Boolean
  }

  initialize() {
    this.boundHandleInitialize = this.handleInitialize.bind(this);
    this.boundHandleStart = this.handleStart.bind(this);
    this.boundHandleProgress = this.handleProgress.bind(this);
    this.boundHandleError = this.handleError.bind(this);
    this.boundHandleEnd = this.handleEnd.bind(this);
  }

  connect() {
    document.addEventListener('direct-upload:initialize', this.boundHandleInitialize)
    document.addEventListener('direct-upload:start', this.boundHandleStart)
    document.addEventListener('direct-upload:progress', this.boundHandleProgress)
    document.addEventListener('direct-upload-error', this.boundHandleError)
    document.addEventListener('direct-upload:end', this.boundHandleEnd)
  }

  disconnect() {
    document.removeEventListener('direct-upload:initialize', this.boundHandleInitialize)
    document.removeEventListener('direct-upload:start', this.boundHandleStart)
    document.removeEventListener('direct-upload:progress', this.boundHandleProgress)
    document.removeEventListener('direct-upload-error', this.boundHandleError)
    document.removeEventListener('direct-upload:end', this.boundHandleEnd)
  }

  handleInitialize(event) {
    const { target, detail } = event;
    const { id, file } = detail;
    target.insertAdjacentHTML('beforebegin', `
      <div id="direct-upload-${id}" class="direct-upload direct-upload--pending">
        <div id="direct-upload-progress-${id}" class="direct-upload__progress" style="width: 0%"></div>
        <span class="direct-upload__filename"></span>
      </div>
    `);
    target.previousElementSibling.querySelector(`.direct-upload__filename`).textContent = file.name;
  }

  handleStart(event) {
    const { id } = event.detail;
    const element = document.getElementById(`direct-upload-${id}`);
    element.classList.remove('direct-upload--pending');
    this.uploadingValue = true;
  }

  handleProgress(event) {
    const { id, progress } = event.detail;
    const progressElement = document.getElementById(`direct-upload-progress-${id}`);
    progressElement.style.width = `${progress}%`;
  }

  handleError(event) {
    event.preventDefault()
    const { id, error } = event.detail;
    const element = document.getElementById(`direct-upload-${id}`);
    element.classList.add('direct-upload--error');
    element.setAttribute('title', error);
    this.uploadingValue = false;
  }

  handleEnd(event) {
    const { id } = event.detail;
    const element = document.getElementById(`direct-upload-${id}`);
    element.classList.add('direct-upload--complete');
    this.uploadingValue = false;
  }

  leavingPage(event) {
    if (this.uploadingValue === true) {
      if (event.type === "turbo:before-visit") {
        if (!window.confirm(LEAVING_PAGE_MESSAGE)) { 
          event.preventDefault()
        }
      } else {
        event.returnValue = LEAVING_PAGE_MESSAGE;
        return event.returnValue;
      }
    } 
  }
}
