import ApplicationController from './application_controller';
import axios from 'axios';
import { post, destroy } from '@rails/request.js';
import * as Turbo from '@hotwired/turbo';
import { UAParser } from 'ua-parser-js';

const MAX_SIZE = 1073741824;
const LEAVING_PAGE_MESSAGE_UPLOAD = "You have a recording upload in progress. If you leave, you will need to upload this recording again. Are you sure you want to exit this page?"
const CancelToken = axios.CancelToken;
const source = CancelToken.source();

export default class extends ApplicationController {
  static targets = [
    'progress',
    'progressBar',
    'progressCancel',
    'progressFileName',
    'progressPercentage',
    'fileInput',
    'dropZone',
    'dropZoneContainer',
    'errorMsg',
    'errorMsgContent',
    'form',
    'submitButton',
    'cancelButton',
    'dropZoneLabel',
    'dropZoneUploadButton',
    'recordingUploadFrame',
    'recordingUrl',
    'audioUploaded',
    'retryButton'
  ]

  static values = {
    directUploadUrl: String,
    hasAudio: Boolean,
    newRecordingUrl: String
  }

  connect() {
    const parser = new UAParser();
    const OSName = parser.getOS().name;
    if (OSName === 'iOS' || OSName === 'Android') {
      this.dropZoneLabelTarget.innerHTML = 'Choose audio file to upload';
      this.dropZoneUploadButtonTarget.innerHTML = 'Select file';
    }

    if (OSName === 'iOS') {
      // Force iOS to use file picker
      this.fileInputTarget.setAttribute('accept', 'application/octet-stream');
    }
  }

  dragOver(event) {
    event.preventDefault();
    event.stopPropagation();
    this.dropZoneTarget.classList.add('drag-drop__zone--active');
  }

  dragLeave(event) {
    event.preventDefault();
    event.stopPropagation();
    this.dropZoneTarget.classList.remove('drag-drop__zone--active');
  }

  async requestUpload(e) {
    if (this.fileInputTarget.files && this.fileInputTarget.files[0]) {

      e.preventDefault();

      this.file = this.fileInputTarget.files[0];

      if (!this.file.type.match('audio.*')) {
        e.preventDefault();
        this.fileInputTarget.value = '';
        alert('Please select an audio file');
        return;
      } else if (this.file.size > MAX_SIZE) {
        e.preventDefault();
        this.fileInputTarget.value = '';
        alert('Please select an audio file smaller than 1GB');
        return;
      }

      const response = await post(this.newRecordingUrlValue, {
        body: JSON.stringify({ 
          title: this.file.name,
          saved_by_user: false
        }),
        responseKind: 'turbo-stream'
      });

      if (response.ok) {
        this.submitButtonTarget.classList.remove('hide');
        this.progressTarget.classList.remove('hide');
        this.progressFileNameTarget.innerHTML = this.file.name;
        this.dropZoneContainerTarget.style.display = 'none';
      }
    }
  }

  formTargetConnected(element) {
    if (!this.file) return;
    if (this.audioUploadedValue) return;
    
    this.recordingUrlValue = this.formTarget.action;
    this.cancelButtonTarget.href = this.formTarget.action;
    this.cancelButtonTarget.dataset.turboMethod = 'delete';
    this.cancelButtonTarget.dataset.turboConfirm = LEAVING_PAGE_MESSAGE_UPLOAD;

    this.uploadRecording();
  }

  cancelRecording() {
    source.cancel();
  }

  async uploadRecording() {
    this.progressBarTarget.value = 0;
    this.retryButtonTarget.disabled = true;
    this.retryButtonTarget.style.display = 'none';
    this.progressPercentageTarget.style.display = 'block';
    this.progressTarget.classList.remove('progress--error');
    this.progressFileNameTarget.innerHTML = this.file.name;

    const config = {
      cancelToken: source.token,
      onUploadProgress: progressEvent => {
        const progressComplete = Math.ceil((progressEvent.loaded / progressEvent.total) * 100);
        this.progressBarTarget.value = progressComplete;
        this.progressPercentageTarget.innerHTML = `${progressComplete}%`;
      }
    };

    await axios.put(this.formTarget.dataset.directUploadUrl, this.file, config)
      .then(response => {
        console.log('[Recording upload] - Successful recording upload: ', response);
        this.onUploadSuccess();
      })
      .catch(error => {
        console.log('[Recording upload] - Error uploading recording:', error);
        this.submitButtonTarget.disabled = true;
        this.retryButtonTarget.disabled = false;
        this.retryButtonTarget.style.display = 'block';
        this.progressTarget.classList.add('progress--error');
        this.progressBarTarget.value = 100;
        this.progressFileNameTarget.innerHTML = 'Error uploading audio';
        this.progressPercentageTarget.style.display = 'none';
      });
  }

  async onUploadSuccess() {
    this.progressFileNameTarget.innerHTML = 'Upload complete. Click save to continue...';
    if (this.formTarget.dataset.uploadCompleteUrl) {
      await axios.post(this.formTarget.dataset.uploadCompleteUrl)
        .then(response => {
          console.log('[Recording upload] - Marking upload as complete: ', response);
          this.submitButtonTarget.disabled = false;
          this.audioUploadedValue = true;
        })
        .catch(error => {
          console.log('[Recording upload] - Error uploading: ', error);
          this.handleUploadError();
        });
    }
  }

  handleUploadError() {
    this.dropZoneContainerTarget.remove();
    this.progressTarget.remove();
    this.recordingUploadFrameTarget.remove();
    this.submitButtonTarget.disabled = true;
    this.submitButtonTarget.classList.add('hide');
    this.errorMsgContentTarget.innerHTML = `
      There was an error uploading your recording. <br> We have saved a draft which you can <br> <a href="${this.recordingUrlValue}" data-turbo-action="replace">edit</a> or <a href="${this.recordingUrlValue}" data-turbo-method="delete" data-turbo-action="replace">delete</a>
    `;
    this.errorMsgTarget.classList.remove('hidden');
  }
}