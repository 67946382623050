import ApplicationController from './application_controller';

let player;

export default class extends ApplicationController {
  static targets = [
    'playButton',
    'pauseButton',
    'seekSlider',
    'currentTime',
    'duration'
  ]
  static values = {
    recordingTitle: String,
    recordingUrl: String,
    recordingFormat: String,
    recordingImageSrc: String,
    seekValue: Number,
    duration: Number,
    position: Number
  }

  initAudio() {
    this.audio = new Audio(this.recordingUrlValue);
    this.audio.crossOrigin = 'anonymous';
    this.audio.load();

    this.boundOnLoaded = this.onLoaded.bind(this);
    this.boundOnEnded = this.onEnded.bind(this);
    this.boundOnError = this.onError.bind(this);
    this.boundOnPlaying = this.onPlaying.bind(this);
    this.audio.addEventListener('loadeddata', this.boundOnLoaded);
    this.audio.addEventListener('ended', this.boundOnEnded);
    this.audio.addEventListener('error', this.boundOnError);
    this.audio.addEventListener('playing', this.boundOnPlaying);
  }

  onLoaded() {
    console.log('[Audio] - Loaded data');
    if (this.hasSeekSliderTarget) {
      this.seekSliderTarget.max = Math.floor(this.audio.duration);
      this.seekSliderTarget.ariaValueMax = Math.floor(this.audio.duration);
    }

    if ('mediaSession' in navigator) {
      console.log('[Audio] - Media session is supported');
      navigator.mediaSession.metadata = new MediaMetadata({
        title: this.hasRecordingTitleValue ? this.recordingTitleValue : '',
        artwork: [{ src: this.hasRecordingImageSrcValue ? this.recordingImageSrcValue : '' }]
      });
      navigator.mediaSession.setActionHandler('play', () => {
        this.play();
      });
      navigator.mediaSession.setActionHandler('pause', () => {
        this.pause();
      });
      navigator.mediaSession.setPositionState({
        duration: this.audio.duration,
        playbackRate: this.audio.playbackRate,
        position: this.audio.currentTime
      });
    }
  }

  onEnded() {
    console.log('[Audio] - Ended');
    this.reset();
  }

  onError() {
    console.log('[Audio] - Error');
    let msg = 'Unable to load audio: ';
    switch(this.audio.error.code) {
      case this.audio.error.MEDIA_ERR_ABORTED:
        msg += 'aborted by user';
        break;
      case this.error.MEDIA_ERR_NETWORK:
        msg += 'network error';
        break;
      case this.error.MEDIA_ERR_DECODE:
        msg += 'unable to decode';
        break;
      case this.error.MEDIA_ERR_SRC_NOT_SUPPORTED:
        msg += 'missing file or unsupported format';
        break;
      default:
        msg += 'An error occured';
        break;
    }
  }

  onPlaying() {
    console.log('[Audio] - Playing');
    if (this.hasSeekSliderTarget) {
      this.progress = requestAnimationFrame(this.seekProgress.bind(this));
    }
  }

  reset() {
    if(this.audio) {
      this.audio.currentTime = 0;
      this.audio.pause();
      this.audio.removeEventListener('loadeddata', this.boundOnLoaded);
      this.audio.removeEventListener('ended', this.boundOnEnded);
      this.audio.removeEventListener('error', this.boundOnError);
      this.audio.removeEventListener('playing', this.boundOnPlaying);
      this.audio.removeEventListener('pause', this.boundOnPlaying);
      this.audio = null;
    };
    cancelAnimationFrame(this.progress);
    if (this.hasPlayButtonTarget) {
      this.playButtonTarget.style.display = 'flex';
    }
    if (this.hasPauseButtonTarget) {
      this.pauseButtonTarget.style.display = 'none';
    }
    if (this.hasCurrentTimeTarget) {
      this.currentTimeTarget.textContent = '00:00:00';
    }
    if (this.hasSeekSliderTarget) {
      this.seekSliderTarget.style.background = null;
      this.seekSliderTarget.value = 0;
    }
    this.seekValue = 0;
    this.position = 0;
  }

  play() {
    if (!this.audio) {
      this.initAudio();
    }

    if(player === this && (player.audio.duration > 0 && !player.audio.paused)) {
      player.pause();
    }

    const prevPlayer = player;
    player = this;
    if(prevPlayer && prevPlayer !== this) {
      prevPlayer.reset();
    }

    player.playButtonTarget.style.display = 'none';
    player.pauseButtonTarget.style.display = 'flex';
    player.audio.play();
  }

  pause() {
    player.audio.pause();
    player.playButtonTarget.style.display = 'flex';
    player.pauseButtonTarget.style.display = 'none';
    cancelAnimationFrame(this.progress);
  }

  foward15() {
    if (!this.audio) {
      this.initAudio();
      this.play();
    }

    this.audio.currentTime = this.audio.currentTime + 15;
  }

  back15() {
    if (!this.audio) {
      this.initAudio();
      this.play();
    }

    if (this.audio.currentTime <= 15) {
      this.audio.currentTime = 0;
    } else {
      this.audio.currentTime = this.audio.currentTime - 15;
    }
  }

  seekProgress() {
    const seek = this.audio.currentTime || 0;
    const progressPosition = ((seek / this.audio.duration + 0.01) * 100) | 0;
    this.seekSliderTarget.value = seek;
    this.seekSliderTarget.ariaValueNow = seek;
    this.seekSliderTarget.ariaValueText = seek;
    this.setBackgroundColor(progressPosition);
    this.currentTimeTarget.textContent = this.calculateTime(Math.round(seek));
    if(this.audio.duration > 0 && !this.audio.paused) {
      this.progress = requestAnimationFrame(this.seekProgress.bind(this));
    }
  }

  displayDuration() {
    this.durationTarget.textContent = this.calculateTime(this.audio.duration);
  }

  displayError(msg) {
    this.durationTarget.textContent = msg;
  }

  calculateTime(secs) {
    const date = new Date(null);
    date.setSeconds(secs);
    return date.toISOString().substr(11, 8);
  }

  onChange(event) {
    if (!this.audio) {
      this.initAudio();
    }

    const newValue = event.target.value;
    this.audio.currentTime = newValue;
    this.currentTimeTarget.textContent = this.calculateTime(newValue);
    this.play();
  }

  onInput(event) {
    if(!this.audio) {
      this.initAudio();
    }

    if(this.audio.duration > 0 && !this.audio.paused) {
      cancelAnimationFrame(this.progress);
    }
    const newValue = event.target.value;
    const progressPosition = ((newValue / this.audio.duration + 0.01) * 100) | 0;
    this.setBackgroundColor(progressPosition);
  }

  setBackgroundColor(progressPosition) {
    this.seekSliderTarget.style.background = `
      linear-gradient(to right, var(--color__background) 0%,
      var(--color__background) ${progressPosition}%,
      rgb(var(--color__black-rgb), .3) ${progressPosition}%,
      rgb(var(--color__black-rgb), .3) 100%)
    `;
  }

  onKeyDown(event) {
    console.log('keydown');
  }

  teardown() {
    console.log('Tearing down the audio controllers');
    this.reset();
    player = null;
  }

  listViewClick(event) {
    if (event.target.tagName.toLowerCase() === 'button') {
      event.preventDefault();
    }
  }

  stopAudio() {
    this.teardown();
  }
}
