import ApplicationController from './application_controller';

export default class extends ApplicationController {
  initialize() {
    this.boundHandleClickOutside = this.handleClickOutside.bind(this);
    this.boundHandleClickInside = this.handleClickInside.bind(this);
  }

  connect() {
    document.addEventListener('click', this.boundHandleClickOutside);
    document.addEventListener('click', this.boundHandleClickInside);
  }

  disconnect() {
    this.element.open = false;
    document.removeEventListener('click', this.boundHandleClickOutside);
    document.removeEventListener('click', this.boundHandleClickInside);
  }

  toggle(event) {
    if (this.element.hasAttribute('disabled')) {
      event.preventDefault();
    }
  }

  handleClickOutside(event) {
    if(!this.element.contains(event.target)){
      this.element.open = false;
    }
  }

  handleClickInside(event) {
    if(event.target.tagName.toLowerCase() === 'a' || event.target.tagName.toLowerCase() === 'button') {
      this.element.open = false;
    }
  }
}