import ApplicationController from './application_controller';

export default class extends ApplicationController {
  static targets = [
    'menuWrapper'
  ]

  openMenu() {
    this.menuWrapperTarget.classList.add('menu-open');
  }

  closeMenu() {
    this.menuWrapperTarget.classList.remove('menu-open');
  }
}