import ApplicationController from './application_controller';
import { loadingSpinner } from '../vendor/loadingSpinner';

export default class extends ApplicationController {
  static values = {
    dataUrl: String,
    title: String
  };

  connect() {
    if (this.hasDataUrlValue) {
      this.showCount();
    }
  }

  private;

  showCount() {
    (async () => {
      const loader = loadingSpinner(this.element);
      const response = await fetch(this.dataUrlValue, {
        method: "GET",
        headers: {
          Accept: "application/json"
        },
      });
      const jsonData = await response.json();
      loader.stop();
      this.element.innerHTML = `<strong class="analytics__number">${jsonData.listenerHours || 0}</strong> ${this.titleValue}`;
    })();
  }
}