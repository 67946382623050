import HighCharts from 'highcharts';
import ApplicationController from './application_controller';
import { loadingSpinner } from '../vendor/loadingSpinner';

export default class extends ApplicationController {
  static values = {
    dataUrl: String,
    style: String
  };
  
  connect() {
    this.renderChart();
  }

  async renderChart() {
    const loader = loadingSpinner(this.element);
    const response = await fetch(this.dataUrlValue, {
      method: "GET",
      headers: {
        Accept: "application/json"
      },
    });
    const jsonData = await response.json();
    const totalUniqueListeners = jsonData.timeSeries.total_unique_listeners;
    HighCharts.chart(this.element, {
      chart: {
        spacing: 16,
        style: {
          fontFamily: 'Montserrat'
        }
      },
      subtitle: {
        text: "Last 30 days",
        floating: true,
        align: 'right',
        x: -10,
        y: 50,
        style: {
          color: '#000000',
          fontSize: '12px'
        }
      },
      title: {
        text: totalUniqueListeners.name,
        floating: true,
        align: 'right',
        x: -10,
        y: 30,
        style: {
          color: '#000000',
          fontSize: '12px',
          fontWeight: 'bold'
        }
      },
      tooltip: {
        formatter: function () {
          return this.y;
        }
      },
      legend: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      series: [{
        type: 'area',
        fillColor: {
          linearGradient: [0, 0, 0, 300],
          stops: [
            [0, HighCharts.Color('#ed1c24').setOpacity(0.6).get('rgba')],
            [0.6, HighCharts.Color('#ed1c24').setOpacity(0.1).get('rgba')],
            [1, HighCharts.Color('#ed1c24').setOpacity(0).get('rgba')]
          ]
        },
        color: '#ed1c24',
        width: 4,
        marker: {
          radius: 0
        },
        data: totalUniqueListeners.data
      }],
      yAxis: {
        labels: {
          enabled: this.styleValue === 'minimal' ? false : true
        },
        gridLineWidth: 0,
        title: {
          text: null
        },
        min: 0,
        minRange: 10,
        minTickInterval: 1
      },
      xAxis: this.styleValue === 'minimal' ? {
        visible: false
      } : {
        type: 'datetime',
        categories: totalUniqueListeners.data.map((element) => element[0])
      }
    });
    loader.stop();
  }
}