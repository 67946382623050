import ApplicationController from './application_controller';

export default class extends ApplicationController {
  static targets = [
    'child'
  ]

  toggleChild() {
    this.childTargets.forEach((el) => {
      el.classList.toggle('hidden');
    });
  }
}