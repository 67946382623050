import ApplicationController from './application_controller';

export default class extends ApplicationController {
  static targets = [
    'recordingCheckbox',
    'selectToggle',
    'removeToggle',
  ]

  connect() {
    if (this.removeToggleTarget) {
      this.removeToggleTarget.setAttribute('disabled', true);
    }

    document.addEventListener('turbo:frame-load', (event) => { 
      if (!event.target.matches('.collection-search__frame')) return;
      document.getElementsByClassName('wrapper')[0].scrollTo(0, 0);
    });
  }

  selectAll(event) {
    if (event.currentTarget.innerHTML === 'Select all') {
      event.currentTarget.innerHTML = 'Select none';
      this.recordingCheckboxTargets.forEach(checkbox => {
        checkbox.checked = true;
        this.removeToggleTarget.removeAttribute('disabled');
      });
    } else {
      event.currentTarget.innerHTML = 'Select all';
      this.recordingCheckboxTargets.forEach(checkbox => {
        checkbox.checked = false;
        this.removeToggleTarget.setAttribute('disabled', true);
      });
    }
  }

  toggleCheckbox(event) {
    let setDisabled = true;
    if (event.currentTarget.checked) {
      this.removeToggleTarget.removeAttribute('disabled');
      this.selectToggleTarget.innerHTML = 'Select none';
    } else {
      this.recordingCheckboxTargets.forEach(checkbox => {
        if (checkbox.checked) {
          setDisabled = false;
        };
      });
      if (this.recordingCheckboxTargets.every((checkbox) => !checkbox.checked)) {
        setDisabled = true;
      }
      if (setDisabled) {
        this.selectToggleTarget.innerHTML = 'Select all';
        this.removeToggleTarget.setAttribute('disabled', true);
      }
    }
  }



}
