import ApplicationController from './application_controller';

export default class extends ApplicationController {
  static targets = [
    'progress',
    'title',
    'hint'
  ]
  static values = {
    defaultText: String,
    poorText: String,
    okText: String,
    goodText: String
  }

  focus(e) {
    if(event.currentTarget.value.length >= 0) {
      this.hintTarget.classList.remove('hidden');
    }
  }

  blur(e) {
    this.hintTarget.classList.add('hidden');
  }

  keyUp(e) {
    const pwd = event.currentTarget.value;
    let defaultText = 'Password must be at least 8 characters with a mix of letters and numbers.';

    if(pwd.length >= 0 && pwd.length < 8) {
      this.titleTarget.innerHTML = defaultText;
      this.progressTarget.value = '0';
      this.progressTarget.dataset.value = 0;
      return;
    }

    // Check progress
    let prog = [/[A-Z]/, /[0-9]/, /[a-z]/].reduce(function (memo, test) {
      return memo + test.test(pwd);
    }, 0);

    // Length must be at least 8 chars
    if(pwd.length > 7 && prog > 1) {
      prog++;
    }

    // Display it
    let strength = defaultText;
    let progress = '';
    let dataProgress = '';
    switch (prog) {
      case 0:
      case 1:
      case 2:
        strength = 'Poor Password';
        progress = '33';
        dataProgress = 33;
        break;
      case 3:
        strength = 'OK Password';
        progress = '66';
        dataProgress = 66;
        break;
      case 4:
        strength = 'Good Password!';
        progress = '100';
        dataProgress = 100;
        break;
    }
    this.titleTarget.innerHTML = strength;
    this.progressTarget.value = progress;
    this.progressTarget.dataset.value = dataProgress;
  }
}
