export function getMetaValue(name) {
  const element = findElement(document.head, `meta[name="${name}"]`)
  if (element) {
    return element.getAttribute("content")
  }
}

export function getMetaProperty(property) {
  const element = findElement(document.head, `meta[property="${property}"]`)
  if (element) {
    return element.getAttribute("content")
  }
}

export function findElement(root, selector) {
  if (typeof root == "string") {
    selector = root
    root = document
  }
  return root.querySelector(selector)
}

export function toArray(value) {
  if (Array.isArray(value)) {
    return value
  } else if (Array.from) {
    return Array.from(value)
  } else {
    return [].slice.call(value)
  }
}

export function removeElement(el) {
  if (el && el.parentNode) {
    el.parentNode.removeChild(el);
  }
}

export function insertAfter(el, referenceNode) {
    return referenceNode.parentNode.insertBefore(el, referenceNode.nextSibling);
}

export const rgbToHex = (r, g, b) => '#' + [r, g, b].map(x => {
  const hex = x.toString(16);
  return hex.length === 1 ? '0' + hex : hex;
}).join('');

export function hexToRGB(hex) {
  let parseString = hex;
  if (hex.startsWith('#')) {
    parseString = hex.slice(1, 7);
  }
  if (parseString.length !== 6) {
    return null;
  }
  const r = parseInt(parseString.slice(0, 2), 16);
  const g = parseInt(parseString.slice(2, 4), 16);
  const b = parseInt(parseString.slice(4, 6), 16);
  if (isNaN(r) || isNaN(g) || isNaN(b)) {
    return null;
  }
  return `${r},${g},${b}`;
};

export function postMessage(message) {
  if (
    window.webkit &&
    window.webkit.messageHandlers &&
    window.webkit.messageHandlers.javascriptHandler
  ) {
    console.log('[javascriptHandler] iOS: ', message);
    window.webkit.messageHandlers.javascriptHandler.postMessage(message);
  }
  // Android JS communication
  if (window.javascriptHandler) {
    console.log('[javascriptHandler] Android: ', message);
    // addJavascriptInterface() only works with primitive types and Strings
    // You cannot pass arbitrary Javascript objects.
    if (message instanceof Object) {
      window.javascriptHandler.postMessage(JSON.stringify(message));
    } else {
      window.javascriptHandler.postMessage(message);
    }
  }

  // DA JS communication
  if (window.webchannel) {
    console.log('[javascriptHandler] DA: ', message);
    window.webchannel.postMessage(message);
  }
};