import ApplicationController from './application_controller';

export default class extends ApplicationController {
  static targets = [ 'panel' ]
  static classes = [ 'open' ]

  initialize() {
    this.boundHandleClickOutside = this.handleClickOutside.bind(this);
    this.boundHandleClickInside = this.handleClickInside.bind(this);
  }

  connect() {
    document.addEventListener('click', this.boundHandleClickOutside);
    document.addEventListener('click', this.boundHandleClickInside);
  }

  disconnect() {
    this.close();
    document.removeEventListener('click', this.boundHandleClickOutside);
    document.removeEventListener('click', this.boundHandleClickInside);
  }

  open(e) {
    e.preventDefault();
    this.panelTarget.classList.add(this.openClass);
  }

  close() {
    this.panelTarget.classList.remove(this.openClass);
  }

  handleClickOutside(event) {
    if(!this.element.contains(event.target)){
      this.close();
    }
  }

  handleClickInside(event) {
    if(event.target.tagName.toLowerCase() === 'a') {
      this.close();
    }
  }
}