import ApplicationController from './application_controller';

const confirmPrompt = 'This will remove your custom artwork?';

export default class extends ApplicationController {
  static targets = [
    'channelArtwork',
    'themeArtwork',
    'customImage',
    'themeArtworkSVG',
    'image',
    'imageUploader',
    'imageUploaderActions',
    'fileInput',
    'changeImage',
    'colorInput'
  ]
  
  static values = {
    channelArtworkUrl: String,
    themeBackgroundUrl: String,
    customImage: Boolean,
    url: String,
    themeColor: String
  }

  connect() {
    this.imageUploaderTarget.style.display = this.customImageTarget.checked ? 'block' : 'none';
  }

  setChannelArtwork() {
    this.removeCustomArtworkValues();
  }

  setThemeArtwork() {
    this.removeCustomArtworkValues();
  }

  removeCustomArtworkValues() {
    this.imageUploaderTarget.style.display = 'none';
    this.fileInputTarget.required = false;
    this.fileInputTarget.value = [];
    if (this.hasColorInputTarget && this.hasThemeColorValue) {
      this.colorInputTarget.value = this.themeColorValue;
    }
  }

  selectChannelArtwork(event) {
    if (this.customImageValue) {
      if (confirm(confirmPrompt)) {
        this.setChannelArtwork();
        this.customImageValue = false;
        this.fileInputTarget.value = [];
      } else {
        event.preventDefault();
      }
    } else {
      this.setChannelArtwork();
    }
  }

  selectThemeArtwork(event) {
    if (this.customImageValue) {
      if (confirm(confirmPrompt)) {
        this.setThemeArtwork();
        this.customImageValue = false;
        this.fileInputTarget.value = [];
      } else {
        event.preventDefault();
      }
    } else {
      this.setThemeArtwork();
    }
  }

  selectCustomImage() {
    this.imageUploaderTarget.style.display = 'flex';
    this.fileInputTarget.required = true;
  }
}
