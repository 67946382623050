import ApplicationController from './application_controller';

export default class extends ApplicationController {
  static targets = [
    'scrollArea',
    'pagination'
  ]

  connect() {
    if (this.hasPaginationTarget) {
      this.createObserver();
      this.paginationTarget.classList.add('hidden');
    }
  }

  createObserver() {
    if (!this.hasScrollAreaTarget) return;
    const observer = new IntersectionObserver(
      entries => this.handleIntersect(entries), {
        threshold: [0, 1.0],
      }
    )
    observer.observe(this.scrollAreaTarget)
  }

  handleIntersect(entries) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        this.loadMore()
      }
    })
  }

  loadMore() {
    const next = this.paginationTarget.querySelector('[rel=next]');
    if (!next) return;
    const href = next.href
    fetch(href, {
      headers: {
        Accept: 'text/vnd.turbo-stream.html',
      },
    })
    .then(r => r.text())
    .then(html => Turbo.renderStreamMessage(html))
    .then(_ => history.replaceState(history.state, '', href))
  }
}
