import ApplicationController from './application_controller';

export default class extends ApplicationController {
  static targets = [
    'rssFeedCheckbox'
  ]

  changeAccessLevel(event) {
    if (!this.hasRssFeedCheckboxTarget) return;
    if (event.target.value == 'only_me') {
      this.rssFeedCheckboxTarget.disabled = true;
      this.rssFeedCheckboxTarget.checked = false;
      this.rssFeedCheckboxTarget.parentNode.classList.add('form__row--disabled');
    } else {
      this.rssFeedCheckboxTarget.disabled = false;
      this.rssFeedCheckboxTarget.parentNode.classList.remove('form__row--disabled');
    }
  }
}