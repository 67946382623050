import ApplicationController from './application_controller';
import tinycolor from 'tinycolor2';

export default class extends ApplicationController {
  static targets = []
  static values = {
    color: String
  }

  connect() {
    if (!this.colorValue) {
      return;
    }
    const themeColor = tinycolor(this.colorValue);
    const themeColorIsLight = themeColor.getLuminance() > 0.5;
    const isDarkTheme = themeColor.getLuminance() < 0.01;
    this.element.classList.add(themeColorIsLight ? 'light-contrast' : 'dark-contrast');
    if (isDarkTheme) {
      this.element.classList.add('dark-theme');
    }
  }
}